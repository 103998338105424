import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { usePressAndHold } from "../hook/usePressAndHold";
import UserCard from "./UserCard";
import arrowRight from "../images/components/userList/arrow_right.svg";
import arrowLeft from "../images/components/userList/arrow_left.svg";
import { selectedSubscribedUserState } from "../atoms";
import { useRecoilState } from "recoil";

const borderRadius = "6px";
const scrollAmount = 200;

const Wrapper = styled.div`
  position: relative;
  height: 80px;
  width: 100%;
  margin: 0 auto 32px;
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
  }
`;

const Users = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.dummy ? "space-between" : "flex-start")};
  gap: 40px;
  width: 100%;
  height: 100%;
  padding: 6px 0 0px;

  overflow-x: scroll;
  z-index: 1;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: transparent;
  }
  @media ${({ theme }) => theme.device.tablet} {
    &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.gray300};
    }
  }
  .empty {
    position: relative;
    /* top: 5px; */
    /* left: 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .text {
      white-space: pre-wrap;
      text-align: center;
      font-size: 15px;
      line-height: 20px;
      color: #c0c5d1;
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    /* padding: 10px 16px 0px; */
  }
`;
const UsersWrap = styled.div`
  width: 100%;
  height: 80px;
  padding: 0 56px;
  border: 1px solid #dbdbdb;
  border-radius: ${borderRadius};
  background: #ffffff;
  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 16px;
  }
`;
const NavigateButton = styled.div`
  position: absolute;
  top: calc(50% - 24px);
  width: 48px;
  height: 48px;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
  pointer-events: none;
  z-index: 10;
  &:first-child {
    left: 8px;
    border-radius: ${borderRadius} 0 0 ${borderRadius};
    background: url(${arrowLeft});
  }
  &:last-child {
    right: 8px;
    border-radius: 0 ${borderRadius} ${borderRadius} 0;
    background: url(${arrowRight});
  }
  &.show {
    opacity: 1;
    pointer-events: auto;
  }
`;

export default function UserList({ users }) {
  const userListRef = useRef(null);
  const navButtonLeftRef = useRef(null);
  const navButtonRightRef = useRef(null);
  const { startCounter, stopCounter } = usePressAndHold();
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });
  const [selectedMSeq, setSelectedMSeq] = useRecoilState(selectedSubscribedUserState);

  //UserCard.jsx에서 해당 함수를 실행하여 mSeq값을 전달한다.
  const onSelectSubscribedUserId = (mSeq)=>{
    setSelectedMSeq(mSeq);
  };

  const onClickLeft = () => {
    scrollHorizontally(userListRef.current, -scrollAmount);
  };

  const onClickRight = () => {
    scrollHorizontally(userListRef.current, scrollAmount);
  };

  const onScrollUserList = () => {
    // http://jsfiddle.net/y8Y32/25/
    const userListElement = userListRef.current;
    const containerInnerWidth = userListElement.clientWidth;
    const containerScrollLeft = userListElement.scrollLeft;
    const containerScrollRight = userListElement.scrollLeft + containerInnerWidth;
    const contentWidth = userListElement.scrollWidth;

    showElementRef(navButtonLeftRef);
    showElementRef(navButtonRightRef);

    if (containerScrollLeft <= 0) {
      showElementRef(navButtonLeftRef, false);
    }

    if (containerScrollRight >= contentWidth) {
      showElementRef(navButtonRightRef, false);
    }
  };

  useEffect(() => {
    if (userListRef.current) {
      const userListElement = userListRef.current;

      userListElement.onscroll = onScrollUserList;
    }
  }, []);

  useEffect(() => {
    if (userListRef.current) {
      onScrollUserList();
    }
  }, [users]);

  return (
    <Wrapper>
      {!tablet && (
        <NavigateButton
          ref={navButtonLeftRef}
          onMouseDown={() => startCounter(onClickLeft)}
          onMouseUp={stopCounter}
          onMouseLeave={stopCounter}
          onTouchStart={() => startCounter(onClickLeft)}
          onTouchEnd={stopCounter}
        />
      )}
      <UsersWrap>
        <Users ref={userListRef} dummy={users.length > 0 ? false : true}>
          {users &&
            users.length > 0 &&
            users.map((user, i) => <UserCard key={i} userData={user} onSelectSubscribedUserId={onSelectSubscribedUserId} />)}
          {users && users.length === 0 ? (
            <div className="empty">
              <span className="text">구독한 사용자가 여기에 표시됩니다.</span>
            </div>
          ) : null}
        </Users>
      </UsersWrap>

      {!tablet && (
        <NavigateButton
          ref={navButtonRightRef}
          onMouseDown={() => startCounter(onClickRight)}
          onMouseUp={stopCounter}
          onMouseLeave={stopCounter}
          onTouchStart={() => startCounter(onClickRight)}
          onTouchEnd={stopCounter}
        />
      )}
    </Wrapper>
  );
}

const scrollHorizontally = (element, amount) => {
  element.scrollBy({ left: amount, behavior: "smooth" });
};

const showElementRef = (elementRef, value = true) => {
  const element = elementRef.current;

  if (element) {
    if (value) {
      element.classList.add("show");
    } else {
      element.classList.remove("show");
    }
  }
};
