const deviceSizes = { //@media ${({ theme }) => theme.device.mobile}
  mobile: "480px",
  mobilelg: "590px",
  tablet: "768px",
  labtop: "1024px",
  labtoplg: "1080px",
  desktop: "1280px",
};
const theme = {
  bgColor: "#fff",

  point01: "#F74551",

  red100: "#FDF6F7",
  red200: "#FA9CA2",
  red300: "#CD2530",
  red400: "#770C14",

  gray100: "#FAFAFA",
  gray200: "#F0F0F0",
  gray300: "#DBDBDB",
  gray400: "#A8A4A4",
  gray500: "#777373",
  gray600: "#4E4B4B",
  gray800: "#262626",
  gray1000: "#0F0F10",
  device: {
    mobile: `screen and (max-width: ${deviceSizes.mobile})`,
    mobilelg: `screen and (max-width: ${deviceSizes.mobilelg})`,
    tablet: `screen and (max-width: ${deviceSizes.tablet})`,
    labtop: `screen and (max-width: ${deviceSizes.labtop})`,
    labtoplg: `screen and (max-width: ${deviceSizes.labtoplg})`,
    desktop: `screen and (max-width: ${deviceSizes.desktop})`,
  },
};

export default theme;
