import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import Thumbnail, { ThumbnailDummy } from "./Thumbnail";
import ThumbnailSearchResult from "./ThumbnailSearchResult";

const Wrap = styled.div`
  position: relative;
  display: grid;
  
  grid-template-columns: repeat(${(props) => (props.myRoom ? 4 : 6)}, 265px);

  /* grid-template-columns: repeat(auto-fill, minmax(158px, 1fr)); */
  /* grid-template-columns: repeat(${(props) => (props.myRoom ? 4 : 5)}, 212px); */
  
  justify-content: space-between;

  height: ${(props) => (props.row ? "fit-content" : "fit-content")};

  overflow: hidden;
  width: ${(props) => (props.myRoom ? "892px" : "1768px")};
  grid-row-gap: ${(props) => (props.myRoom ? "25px" : "20px")};
  margin: 0 auto;
  margin-bottom: ${(props) => (props.row ? "0" : "40px")};
  > div {
    width: 100%;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(2, 49%);
    height: fit-content;
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;

export default function ThumbGrid({ data, row, lastElementRef, myRoom, title }) {
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  return (
    <Wrap row={row} myRoom={myRoom}>
      {tablet && row
        ? data.slice(0, 6).map((content, i) => (
            <div key={i} ref={data.length - 1 === i ? lastElementRef : undefined}>
              <ThumbnailSearchResult
                bgurl={content.coverUrl}
                title={content.bookTitle}
                publisher={content.nickName}
                view={content.viewCnt}
                id={content.eSeq}
                favor={content.favorCnt}
                bookUrl={content.bookUrl}
              ></ThumbnailSearchResult>
            </div>
          ))
        : data.map((content, i) => (
            <div key={i} ref={data.length - 1 === i ? lastElementRef : undefined}>
              <ThumbnailSearchResult
                bgurl={content.coverUrl}
                title={content.bookTitle}
                publisher={content.nickName}
                view={content.viewCnt}
                id={content.eSeq}
                favor={content.favorCnt}
                bookUrl={content.bookUrl}
              ></ThumbnailSearchResult>
            </div>
          ))}
    </Wrap>
  );
}
