import styled from "styled-components";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import Container from "../components/Container";
import NewContainer from "../components/NewContainer";
import ThumbGridTemplate from "../components/ThumbGridTemplate";
import UserList from "../components/UserList";
import icon_warning from "../images/pages/SearchResults/icon_warning.svg";
import { useInfiniteScroll } from "../hook/useInfiniteScroll";
import Loading from "../components/Loading";
import HashtagTemplate from "../components/HashtagTemplate";
import { useSearchTemplateData } from "../hook/useSearchTemplateData";

const Searched = styled.div`
  width: 100%;
  min-height: 56vh;
  margin-top: 24px;
  .noneSearched {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    height: 53vh;
    margin: 0 auto;
    color: ${(props) => props.theme.gray500};
    font-family: "Pretendard-SemiBold";
    .icon {
      width: 64px;
      height: 64px;
    }
    .title {
      font-size: 22px;
    }
    .description {
      font-family: "Pretendard-Regular";
      font-size: 14px;
      line-height: 1.7;
    }
  }
`;
const ResultText = styled.div`
  font-family: "Pretendard-Medium";
  font-size: 18px;
  line-height: 21px;
  color: #303441;
  margin-bottom: 16px;
  em {
    color: #9a9ba7;
  }
`;

export default function SearchTemplateResults() {
  const location = useLocation();
  const searchKeyword = getKeyword(location);
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, ebookData, userData, hasMore } = useSearchTemplateData(
    searchKeyword,
    currentPage
  );
  const { lastElementRef } = useInfiniteScroll(isLoading, hasMore, setCurrentPage);
  const isIconList =
    location.state && location.state.iconList ? location.state.iconList : "";
  return (
    <Layout>
      <HashtagTemplate />
      <PageTitle
        title={isIconList ? `${searchKeyword}` : `‘${searchKeyword}’ 검색 결과`}
        kind="searchResult"
        location="searchResult"
      />
      <NewContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {!isIconList && searchKeyword.length >= 2 && userData.length !== 0 && (
              <>
                <ResultText>
                  별명 검색 결과 <em>({userData.length})</em>
                </ResultText>
                <UserList users={userData} />
              </>
            )}

            <Searched>
              {searchKeyword.length >= 2 && ebookData.length > 0 ? (
                <>
                  <ResultText>
                    제목 검색 결과 <em>({ebookData.length})</em>
                  </ResultText>
                  <ThumbGridTemplate data={ebookData} lastElementRef={lastElementRef} />
                </>
              ) : (
                <NoResult
                  text={
                    searchKeyword.length >= 2 && userData.length !== 0 ? "콘텐츠 " : ""
                  }
                />
              )}
            </Searched>
          </>
        )}
      </NewContainer>
    </Layout>
  );
}

function NoResult({ text }) {
  return (
    <>
      <div className="noneSearched">
        <img className="icon" src={icon_warning} alt="" />
        <div className="title">{text}검색 결과가 없습니다.</div>
        <div className="description">
          두 글자 이상 검색어로 검색이 가능합니다. <br />
          검색어를 제대로 입력했는지 확인해주세요.
        </div>
      </div>
    </>
  );
}

const getKeyword = (location) => {
  let keyword = new URLSearchParams(location.search).get("keyword");

  if (!keyword) {
    keyword = "";
  }

  return keyword.trim();
};
