import { useEffect } from "react";
import { useRef } from "react";

export default function Spacer({ width, height }) {
  const spaceRef = useRef(null);

  useEffect(() => {
    const spaceElement = spaceRef.current;

    if (spaceElement) {
      spaceElement.style.width = width;
      spaceElement.style.height = height;
    }
  }, [width, height]);

  return <div ref={spaceRef} />;
}
