import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wraper = styled.div`
  width: 574px;
  aspect-ratio: 574/136;
  img {
    width: 100%;
    border-radius: 10px;
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    aspect-ratio: 343/135;
  }
`;

export default function AD({
  imgSrc,
  linkTo
}) {
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });
  return (
    <Wraper>
      <Link to={linkTo ? linkTo : "/"}>
        {imgSrc ? <img src={!tablet ? imgSrc[0] : imgSrc[1]} /> : ""}
      </Link>
    </Wraper>
  );
}
