import styled from "styled-components";

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #ddd;
    font-family: Pretendard-Medium;

    & thead th {
        height: 50px;
        vertical-align: middle;
        background-color: #f9f9f9;
        border-bottom: 1px solid #ddd;
    }

    & tbody tr {
        padding: 10px 0;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }
    
    & tbody tr td {
        width: 20%;
        min-height: 50px;
        text-align: center;
        vertical-align: middle;
        color: #858585;
        font-family: Pretendard-Light;
    }
    & tbody tr td.bookContent {
        display: flex;
        align-items: flex-start;
        column-gap: 10px;
        width: 350px;
        padding: 10px;
    }
    & tbody tr td.bookContent img {
        width: 150px;
        border: 1px solid #eee;
        cursor: pointer;
    } 
    & .bookInfo {
        width: 250px;
        text-align: left;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        margin-top: 5px;
    }
    
    & .bookTitle {
        font-size: 1rem;
        font-weight: bold;
        color: #303441;
    }
    & .bookDesc {
        font-size: 0.8rem;
        color: #9a9ba7;
    
    }
    & .tagNames {
        font-size: 0.8rem;
        color: #babbc7;
    }
    & .nodata {
        height: 50px;
        text-align: center;
        color: #858585;
        font-family: Pretendard-Light;
    }
    & .cutString {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;    
    }
`;

export const StatEbookList = (props) => {
    // console.log(props.ebookList);

    const handleGoEbook = (e, eSeq) => {
        window.location.href=`/ebook/${eSeq}`;
    }

    //////////////////////////////////////////////////////////////
    // 세로형 모바일 디바이스인 경우
    // 운영체계가 아이폰 또는 안드로이드이고 너비가 479 이하인 경우
    //////////////////////////////////////////////////////////////

    const user = navigator.userAgent;
    const windowInnerWidth = window.innerWidth;

    if ((((user.indexOf("iPhone") > -1 || user.indexOf("Android")) > -1)) && (windowInnerWidth < 480)) {
        return (
            <>
                {props.active === 'totalViewCnt' && (
                    <Table>
                        <colgroup>
                            <col width="*"/>
                        </colgroup>                    
                        <thead>
                            <tr>
                                <th> 콘텐츠 </th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.ebookList.length > 0 && props.ebookList?.map((ebook, index) => (
                                <tr key={index}>
                                    <td className="bookContent">
                                        <img 
                                            src={ebook.coverUrl?.replace(/http:\/\/localhost:8818/,'https://admin.kpub.co.kr')} 
                                            alt={ebook.bookTitle}
                                            onClick={(e) => handleGoEbook(e, ebook.eSeq)}
                                        />
                                        <div className="bookInfo">
                                            <div className="bookTitle cutString"><font size = "2">{ebook.bookTitle}</font></div>
                                            <div className="bookDesc cutString">{ebook.bookDesc}</div>
                                            <div className="tagNames cutString">{ebook.tagNames}</div>
                                        </div>
                                    </td>
                                    <tr>
                                        <td valign = "middle"><font size = "2"> 조회수 </font></td> <td><font size = "2">{ebook.totalViewCnt}</font></td>
                                        <td valign = "middle"><font size = "2"> 좋아요 </font></td> <td><font size = "2">{ebook.totalLikeCnt}</font></td>
                                    </tr>
                                    <tr>
                                        <td valign = "middle"><font size = "2"> 댓글수 </font></td> <td><font size = "2">{ebook.totalCmtCnt}</font></td>
                                        <td valign = "middle"><font size = "2"> 업데이트 </font></td> <td><font size = "2">{ebook.regDate}</font></td>
                                    </tr>
                                </tr>
                            ))}    
                            {props.ebookList.length === 0 && (
                                <tr>
                                    <td className="nodata" colSpan="5">데이터가 없습니다.</td>
                                </tr>
                            
                            )}                        
                        </tbody>
                    </Table>
                )}
                {props.active === 'totalLikeCnt' && (
                    <Table>
                        <colgroup>
                            <col width="*"/>
                        </colgroup>                    
                        <thead>
                            <tr>
                                <th>콘텐츠</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.ebookList.length > 0 && props.ebookList?.map((ebook, index) => (
                                <tr key={index}>
                                    <td className="bookContent">
                                        <img 
                                            src={ebook.coverUrl?.replace(/http:\/\/localhost:8818/,'https://admin.kpub.co.kr')} 
                                            alt={ebook.bookTitle}
                                            onClick={(e) => handleGoEbook(e, ebook.eSeq)}
                                        />
                                        <div className="bookInfo">
                                            <div className="bookTitle cutString"><font size = "2">{ebook.bookTitle}</font></div>
                                            <div className="bookDesc cutString">{ebook.bookDesc}</div>
                                            <div className="tagNames cutString">{ebook.tagNames}</div>
                                        </div>
                                    </td>
                                    <tr>
                                        <td valign = "middle"><font size = "2"> 좋아요 </font></td> <td><font size = "2">{ebook.totalLikeCnt}</font></td>
                                        <td valign = "middle"><font size = "2"> 조회수 </font></td> <td><font size = "2">{ebook.totalViewCnt}</font></td>
                                    </tr>
                                    <tr>
                                        <td valign = "middle"><font size = "2"> 댓글수 </font></td> <td><font size = "2">{ebook.totalCmtCnt}</font></td>
                                        <td valign = "middle"><font size = "2"> 업데이트 </font></td> <td><font size = "2">{ebook.regDate}</font></td>
                                    </tr>
                                </tr>
                            ))}    
                            {props.ebookList.length === 0 && (
                                <tr>
                                    <td className="nodata" colSpan="5">데이터가 없습니다.</td>
                                </tr>
                            
                            )}
                        
                        </tbody>
                    </Table>
                )}
                {props.active === 'totalCmtCnt' && (
                    <Table>
                        <colgroup>
                            <col width="*"/>
                        </colgroup>           
                        <thead>
                            <tr>
                                <th>콘텐츠</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.ebookList.length > 0 && props.ebookList?.map((ebook, index) => (
                                <tr key={index}>
                                    <td className="bookContent">
                                        <img 
                                            src={ebook.coverUrl?.replace(/http:\/\/localhost:8818/,'https://admin.kpub.co.kr')} 
                                            alt={ebook.bookTitle}
                                            onClick={(e) => handleGoEbook(e, ebook.eSeq)}
                                        />
                                        <div className="bookInfo">
                                            <div className="bookTitle cutString"><font size = "2">{ebook.bookTitle}</font></div>
                                            <div className="bookDesc cutString">{ebook.bookDesc}</div>
                                            <div className="tagNames cutString">{ebook.tagNames}</div>
                                        </div>
                                    </td>
                                    <tr>
                                        <td valign = "middle"><font size = "2"> 댓글수 </font></td> <td><font size = "2">{ebook.totalCmtCnt}</font></td>
                                        <td valign = "middle"><font size = "2"> 조회수 </font></td> <td><font size = "2">{ebook.totalViewCnt}</font></td>
                                    </tr>
                                    <tr>
                                        <td valign = "middle"><font size = "2"> 좋아요 </font></td> <td><font size = "2">{ebook.totalLikeCnt}</font></td>
                                        <td valign = "middle"><font size = "2"> 업데이트 </font></td> <td><font size = "2">{ebook.regDate}</font></td>
                                    </tr>
                                </tr>
                            ))}    
                            {props.ebookList.length === 0 && (
                                <tr>
                                    <td className="nodata" colSpan="5">데이터가 없습니다.</td>
                                </tr>
                            
                            )}                        
                        </tbody>
                    </Table>
                )}
                {props.active === 'totalSubCnt' && (
                    <Table>
                        <colgroup>
                            <col width="*"/>
                        </colgroup>                    
                        <thead>
                            <tr>
                                <th>콘텐츠</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.ebookList.length > 0 && props.ebookList?.map((ebook, index) => (
                                <tr key={index}>
                                    <td className="bookContent">
                                        <img 
                                            src={ebook.coverUrl?.replace(/http:\/\/localhost:8818/,'https://admin.kpub.co.kr')} 
                                            alt={ebook.bookTitle}
                                            onClick={(e) => handleGoEbook(e, ebook.eSeq)}
                                        />
                                        <div className="bookInfo">
                                            <div className="bookTitle cutString"><font size = "2">{ebook.bookTitle}</font></div>
                                            <div className="bookDesc cutString">{ebook.bookDesc}</div>
                                            <div className="tagNames cutString">{ebook.tagNames}</div>
                                        </div>
                                    </td>
                                    <tr>
                                        <td valign = "middle"><font size = "2"> 조회수 </font></td> <td><font size = "2">{ebook.totalViewCnt}</font></td>
                                        <td valign = "middle"><font size = "2"> 좋아요 </font></td> <td><font size = "2">{ebook.totalLikeCnt}</font></td>
                                    </tr>
                                    <tr>
                                        <td valign = "middle"><font size = "2"> 댓글수 </font></td> <td><font size = "2">{ebook.totalCmtCnt}</font></td>
                                        <td valign = "middle"><font size = "2"> 업데이트 </font></td> <td><font size = "2">{ebook.regDate}</font></td>
                                    </tr>
                                </tr>
                            ))}    
                            {props.ebookList.length === 0 && (
                                <tr>
                                    <td className="nodata" colSpan="5">데이터가 없습니다.</td>
                                </tr>
                            
                            )}                        
                        </tbody>
                    </Table>
                )}                                    
            </>
        );
    }

    else{
    return (
        <>
            {props.active === 'totalViewCnt' && (
                <Table>
                    <colgroup>
                        <col width="*"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="15%"/>
                    </colgroup>                    
                    <thead>
                        <tr>
                            <th>콘텐츠</th>
                            <th>조회수</th>
                            <th>좋아요</th>
                            <th>댓글수</th>
                            <th>업로드</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.ebookList.length > 0 && props.ebookList?.map((ebook, index) => (
                            <tr key={index}>
                                <td className="bookContent">
                                    <img 
                                        src={ebook.coverUrl?.replace(/http:\/\/localhost:8818/,'https://admin.kpub.co.kr')} 
                                        alt={ebook.bookTitle}
                                        onClick={(e) => handleGoEbook(e, ebook.eSeq)}
                                    />
                                    <div className="bookInfo">
                                        <div className="bookTitle cutString">{ebook.bookTitle}</div>
                                        <div className="bookDesc cutString">{ebook.bookDesc}</div>
                                        <div className="tagNames cutString">{ebook.tagNames}</div>
                                    </div>
                                </td>
                                <td>{ebook.totalViewCnt}</td>
                                <td>{ebook.totalLikeCnt}</td>
                                <td>{ebook.totalCmtCnt}</td>
                                <td>{ebook.regDate}</td>
                            </tr>
                        ))}

                        {props.ebookList.length === 0 && (
                            <tr>
                                <td className="nodata" colSpan="5">데이터가 없습니다.</td>
                            </tr>
                        
                        )}
                    
                    </tbody>
                </Table>
            )}
            {props.active === 'totalLikeCnt' && (
                <Table>
                    <colgroup>
                        <col width="*"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="15%"/>
                    </colgroup>                    
                    <thead>
                        <tr>
                            <th>콘텐츠</th>
                            <th>좋아요</th>
                            <th>조회수</th>
                            <th>댓글수</th>
                            <th>업로드</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.ebookList.length > 0 && props.ebookList?.map((ebook, index) => (
                            <tr key={index}>
                                <td className="bookContent">
                                    <img 
                                        src={ebook.coverUrl?.replace(/http:\/\/localhost:8818/,'https://admin.kpub.co.kr')} 
                                        alt={ebook.bookTitle}
                                        onClick={(e) => handleGoEbook(e, ebook.eSeq)}
                                    />
                                    <div className="bookInfo">
                                        <div className="bookTitle cutString">{ebook.bookTitle}</div>
                                        <div className="bookDesc cutString">{ebook.bookDesc}</div>
                                        <div className="tagNames cutString">{ebook.tagNames}</div>
                                    </div>
                                </td>
                                <td>{ebook.totalLikeCnt}</td>
                                <td>{ebook.totalViewCnt}</td>
                                <td>{ebook.totalCmtCnt}</td>
                                <td>{ebook.regDate}</td>
                            </tr>
                        ))}

                        {props.ebookList.length === 0 && (
                            <tr>
                                <td className="nodata" colSpan="5">데이터가 없습니다.</td>
                            </tr>
                        
                        )}
                    
                    </tbody>
                </Table>
            )}
            {props.active === 'totalCmtCnt' && (
                <Table>
                    <colgroup>
                        <col width="*"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="15%"/>
                    </colgroup>                    
                    <thead>
                        <tr>
                            <th>콘텐츠</th>
                            <th>댓글수</th>
                            <th>조회수</th>
                            <th>좋아요</th>
                            <th>업로드</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.ebookList.length > 0 && props.ebookList?.map((ebook, index) => (
                            <tr key={index}>
                                <td className="bookContent">
                                    <img 
                                        src={ebook.coverUrl?.replace(/http:\/\/localhost:8818/,'https://admin.kpub.co.kr')} 
                                        alt={ebook.bookTitle}
                                        onClick={(e) => handleGoEbook(e, ebook.eSeq)}
                                    />
                                    <div className="bookInfo">
                                        <div className="bookTitle cutString">{ebook.bookTitle}</div>
                                        <div className="bookDesc cutString">{ebook.bookDesc}</div>
                                        <div className="tagNames cutString">{ebook.tagNames}</div>
                                    </div>
                                </td>
                                <td>{ebook.totalCmtCnt}</td>
                                <td>{ebook.totalViewCnt}</td>
                                <td>{ebook.totalLikeCnt}</td>
                                <td>{ebook.regDate}</td>
                            </tr>
                        ))}

                        {props.ebookList.length === 0 && (
                            <tr>
                                <td className="nodata" colSpan="5">데이터가 없습니다.</td>
                            </tr>
                        
                        )}
                    
                    </tbody>
                </Table>
            )}
            {props.active === 'totalSubCnt' && (
                <Table>
                    <colgroup>
                        <col width="*"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="10%"/>
                        <col width="15%"/>
                    </colgroup>                    
                    <thead>
                        <tr>
                            <th>콘텐츠</th>
                            <th>조회수</th>
                            <th>좋아요</th>
                            <th>댓글수</th>
                            <th>업로드</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.ebookList.length > 0 && props.ebookList?.map((ebook, index) => (
                            <tr key={index}>
                                <td className="bookContent">
                                    <img 
                                        src={ebook.coverUrl?.replace(/http:\/\/localhost:8818/,'https://admin.kpub.co.kr')} 
                                        alt={ebook.bookTitle}
                                        onClick={(e) => handleGoEbook(e, ebook.eSeq)}
                                    />
                                    <div className="bookInfo">
                                        <div className="bookTitle cutString">{ebook.bookTitle}</div>
                                        <div className="bookDesc cutString">{ebook.bookDesc}</div>
                                        <div className="tagNames cutString">{ebook.tagNames}</div>
                                    </div>
                                </td>
                                <td>{ebook.totalViewCnt}</td>
                                <td>{ebook.totalLikeCnt}</td>
                                <td>{ebook.totalCmtCnt}</td>
                                <td>{ebook.regDate}</td>
                            </tr>
                        ))}

                        {props.ebookList.length === 0 && (
                            <tr>
                                <td className="nodata" colSpan="5">데이터가 없습니다.</td>
                            </tr>
                        
                        )}
                    
                    </tbody>
                </Table>
            )}                                    
        </>
    );
    }
}