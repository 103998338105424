import styled from "styled-components";

const Wrapper = styled.div`
  display: ${(props) => (props.flex ? "flex" : "block")};
  /* align-items: stretch; */
  width: 1800px;
  padding: 0 16px;
  margin: 0 auto;
  position: relative;
    
  @media ${({ theme }) => theme.device.mobilelg} {
    min-height: 45vh;
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
  }
`;

export default function Container({ children, flex }) {
  return (
    <Wrapper flex={flex} className="container-Wrapper">
      {children}
    </Wrapper>
  );
}
