import { getCookie, setLoginCookie, delLoginCookie } from "./cookie";
import { RETURN_INT_WITH_TOKEN, RETURN_MSG_WITH_TOKEN } from "./returnInt";

const BASE_URL = process.env.REACT_APP_API_HOST;
const KEY_TOKEN = {
  access: "x-access-token",
  refresh: "x-refresh-token",
};
const REQUEST_METHOD = {
  get: "GET",
  post: "POST",
};

const createHeader = (withAccessToken = false, withRefreshToken = false) => {
  let headers = new Headers();

  if (withAccessToken) {
    const accessToken = getCookie("ACCESSTOKEN");
    if (accessToken) {
      headers.append(KEY_TOKEN.access, accessToken);
    }
  }

  if (withRefreshToken) {
    const refreshToken = getCookie("REFRESHTOKEN");
    headers.append(KEY_TOKEN.refresh, refreshToken);
  }

  return headers;
};

const saveToken = (responseData) => {
  const accessToken = responseData?.row.accessToken;
  const refreshToken = responseData?.row.refreshToken;
  const keepLogin = getCookie("KEEPLOGIN") === "true" ? true : false;
  setLoginCookie(keepLogin, accessToken, refreshToken);
};

/**
 * API fetch request
 * @param {*} url
 * @param {*} body
 * @param {*} tokenOptions 토큰 전송 옵션
 * @returns
 */
export const send = async (url, body, tokenOptions, errorCallback) => {
  const {
    withAccessToken, // 토큰 포함 할지 여부
    withRefreshToken, // 갱신용 토큰 포함 할지 여부
    isRequestRenewToken, // 토큰 만료로 토큰 갱신 요청인지 여부 (토큰 갱신 요청 재시도 방지)
    expireReturnInt, // 토큰 만료 returnInt 값
  } = tokenOptions || {};

  let headers = createHeader(withAccessToken, withRefreshToken);

  const options = {
    method: REQUEST_METHOD.post,
    body,
    headers,
  };

  const response = await fetch(`${BASE_URL}${url}`, options);

  if (response && response.status === 200) {
    let data = await response.json();

    // console.log('send>', url, data, tokenOptions);
    let expireInt = expireReturnInt || RETURN_INT_WITH_TOKEN.expire;
    if (withAccessToken && !isRequestRenewToken && data?.returnInt === expireInt) {
      // 토큰 만료
      // 토큰 만료일 경우 토큰 갱신
      const tokenData = await checkLoginToken();
      if (tokenData?.returnInt === 1 && tokenData?.row.accessToken !== null) {
        // console.log("renew token>", tokenData);
        // 갱신이 되면 refetch 되도록 useQuery를 사용한 곳에서 queryKey에 accessToken을 추가
        // useQuery를 사용안했다면 useEffect에 추가하여 api를 다시 요청하도록 한다
        saveToken(tokenData);
      }
    } else if (
      data &&
      data.returnInt === 0 &&
      data.resultMsg === RETURN_MSG_WITH_TOKEN.fail
    ) {
      // 토큰 인증 실패
      delLoginCookie();
      const errorMsg = data?.resultMsg;
      errorCallback && errorCallback(errorMsg);
    }
    return data;
  } else {
    console.error("서버 접속 실패");
    return undefined;
  }
};

/**
 * 회원 로그인 체크
 */
export const checkLogin = (body) => {
  return send("/checkLogin.ax", body);
};

/**
 * 토큰 로그인 체크
 */
export const checkLoginToken = async () => {
  let headers = createHeader(true, true);
  if (!headers.has(KEY_TOKEN.access) || !headers.has(KEY_TOKEN.access)) {
    console.log("checkLoginToken fetch>", "token is not exist!");
    return undefined;
  }

  const options = {
    method: REQUEST_METHOD.post,
    headers,
  };

  const response = await fetch(`${BASE_URL}/checkLoginToken.ax`, options);

  if (response && response.status === 200) {
    return response.json();
  }
  return undefined;
};

/**
 * 회원 토큰 로그아웃 체크
 */
export const checkLogoutToken = (body) => {
  return send("/checkLogoutToken.ax", body, { withAccessToken: true });
};

/**
 * 회원 아이디 중복체크
 */
export const checkDuplicateId = (body) => {
  return send("/checkDuplicateId.ax", body);
};

/**
 * 회원 등록
 */
export const registerMember = (body) => {
  return send("/registerMember.ax", body);
};

/**
 * 회원 정보 요청
 */
export const getMemberInfo = (body) => {
  return send("/getMemberInfo.ax", body, { withAccessToken: true });
};

/**
 * 회원 정보 변경
 */
export const changeMemberInfo = (body) => {
  return send("/changeMemberInfo.ax", body, { withAccessToken: true });
};

/**
 * 회원 사진 요청
 */
export const getMemberPhoto = (body) => {
  return send("/getMemberPhoto.ax", body, { withAccessToken: true });
};

/**
 * 회원 사진 변경
 */
export const updateMemberPhoto = (body) => {
  return send("/updateMemberPhoto.ax", body, { withAccessToken: true });
};

/**
 * 회원 사진 삭제
 */
export const deleteMemberPhoto = (body) => {
  return send("/deleteMemberPhoto.ax", body, { withAccessToken: true });
};

/**
 * 아이디 찾기
 */
export const getMemberIdByName = (body) => {
  return send("/getMemberIdByName.ax", body);
};

/**
 * 비밀번호 찾기
 */
export const sendFindPwdMail = (body) => {
  return send("/sendFindPwdMail.ax", body);
};


/**
 * 회원인증 메일 전송
 */
export const sendMberAuthCodeConfirmMail = (body) => {
  return send("/sendMberAuthCodeConfirmMail.ax", body);
};

/**
 * 내서재 목록 요청
 */
export const getMyLibraryList = (body) => {
  return send("/ebook/getMyLibraryList.ax", body, { withAccessToken: true });
};

/**
 * 사용자 서재(남의 서재) 목록 요청
 */
export const getUserLibraryList = (body) => {
  return send("/ebook/getUserLibraryList.ax", body, {
    withAccessToken: true,
    expireReturnInt: RETURN_INT_WITH_TOKEN.expire2,
  });
};

/**
 * Ebook 북마크 설정/취소
 */
export const setEbookBookmark = (body) => {
  return send("/ebook/setEbookBookmark.ax", body, { withAccessToken: true });
};

/**
 * Ebook 좋아요 설정/취소
 */
export const setEbookFavor = (body) => {
  return send("/ebook/setEbookFavor.ax", body, { withAccessToken: true });
};

/**
 * Ebook 코멘트 추가
 */
export const appendEbookComment = (body) => {
  return send("/ebook/appendEbookComment.ax", body, { withAccessToken: true });
};

/**
 * Ebook 코멘트 수정
 */
export const updateEbookComment = (body) => {
  return send("/ebook/updateEbookComment.ax", body, { withAccessToken: true });
};

/**
 * Ebook 코멘트 삭제
 */
export const deleteEbookComment = (body) => {
  return send("/ebook/deleteEbookComment.ax", body, { withAccessToken: true });
};

/**
 * Ebook 신고
 */
export const appendEbookComplain = (body) => {
  return send("/ebook/appendEbookComplain.ax", body, { withAccessToken: true });
};

// 콘텐츠
/**
 * 프론트 콘텐츠 목록 요청
 */
export const getFrontContentsList = (body) => {
  return send("/ebook/getFrontContentsList.ax", body);
};

/**
 * 에디터추천 콘텐츠
 */
export const getSuggestionList = (body) => {
  return send("/ebook/getSuggestionList.ax", body);
};

/**
 * 인기콘텐츠
 */
export const getPopularList = (body) => {
  let formdata = new FormData();
  formdata.append("ordering", 1);

  return getFrontContentsList(formdata);
};

/**
 * 최근업로드 콘텐츠
 */
export const getRecentUploadList = (body) => {
  let formdata = new FormData();
  formdata.append("ordering", 0);

  return getFrontContentsList(formdata);
};

/**
 * 이북 상세정보 요청
 */
export const getEbookDetail = (body, errorCallback) => {
  return send(
    "/ebook/getEbookDetail.ax",
    body,
    {
      withAccessToken: true,
      expireReturnInt: RETURN_INT_WITH_TOKEN.expire2,
    },
    errorCallback
  );
};

/**
 * 비슷한 Ebook 목록
 */
export const getSimilarEbookList = (body) => {
  return send("/ebook/getSimilarEbookList.ax", body, { withAccessToken: true });
};

/**
 * Ebook 태그 목록 요청
 */
export const getEbookTagList = (body) => {
  return send("/ebook/getEbookTagList.ax", body);
};

/**
 * Ebook 검색
 */
export const getEbookSearch = (body) => {
  return send("/ebook/getEbookSearchList.ax", body);
};

/**
 * 내가 구독한 목록 요청
 */
export const getMySubscribeList = (body) => {
  return send("/user/getMySubscribeList.ax", body, { withAccessToken: true });
};

/**
 * 사용자 구독하기
 */
export const appendUserSubscribe = (body) => {
  return send("/user/appendUserSubscribe.ax", body, { withAccessToken: true });
};

/**
 * 사용자 구독삭제
 */
export const removeUserSubscribe = (body) => {
  return send("/user/removeUserSubscribe.ax", body, { withAccessToken: true });
}

/**
 * FAQ 카테고리 목록 요청
 */
export const getFaqCategoryList = (body) => {
  return send("/user/getFaqCategoryList.ax", body);
};

/**
 * FAQ 목록 요청
 */
export const getFaqList = (body) => {
  return send("/user/getFaqList.ax", body);
};

/**
 * 피드백 작성
 */
export const appendFeedback = (body) => {
  return send("/user/appendFeedback.ax", body, { withAccessToken: true });
};
/**
 * 마이룸 - 사용자 콘텐츠 목록 요청
 */
export const getUserContentsList = (body) => {
  return send("/getUserContentsList.ax", body, { withAccessToken: true });
};
/**
 * 마이룸 - 사용자 공개여부 변경
 */
export const updateUserContentsPublic = (body) => {
  return send("/updateUserContentsPublic.ax", body, { withAccessToken: true });
};
/**
 * 마이룸 - 사용자 콘텐츠 설명 작성 및 수정
 */
export const updateUserContentsDesc = (body) => {
  return send("/updateUserContentsDesc.ax", body, { withAccessToken: true });
};

/**
 * 마이룸 - 사용자 콘텐츠 삭제
 */
export const deleteBookContents = (body) => {
  return send("/deleteBookContents.ax", body, { withAccessToken: true });
};

// const tokenFNC = async () => {
//   let formdata = new FormData();
//   formdata.append("searchText", "2H윤의 이탈리아 여행");

//   console.log("getUserContentsList", await getUserContentsList(formdata));
// };
// tokenFNC();

// const testFNC = async () => {
//   console.log("getSuggestionList", await getSuggestionList());
// };
// testFNC();

// const testFNC_2 = async () => {
//   console.log("getMyLibraryList", await getMyLibraryList());
// };
// testFNC_2();

////////////////////////////////////////////////////////////////////
// 2023-09-02 신규기능추가.
////////////////////////////////////////////////////////////////////
/**
 * 팝업 목록 요청
 */
export const getPopupInfo = (body) => {
  return send("/getPopupInfo.ax", body);
};

/**
 * 콘텐츠만들기 상담 추가
 */
export const registerConsultInfo = (body) => {
  return send("/registerConsultInfo.ax", body);
};

// 템플릿
/**
 * 프론트 콘텐츠 목록 요청
 */
export const getTemplateContentsList = (body) => {
  return send("/store/getRecentUploadTemplateList.ax", body);
};

// 전체 스토어템플릿 태그목록 얻기
export const getTemplateTagList = (count) => {
  return send(`/store/getTemplateTagList.ax?count=${count}`);
};

/**
 * 템플릿 검색
 */
export const getTemplateSearchList = (body) => {
  return send("/store/getTemplateSearchList.ax", body);
};

/**
 * 템플릿 상세정보 요청
 */
export const getTemplateDetail = (body, errorCallback) => {
  return send(
    "/store/getTemplateDetail.ax",
    body,
    {
      withAccessToken: true,
      expireReturnInt: RETURN_INT_WITH_TOKEN.expire2,
    },
    errorCallback
  );
};

/**
 * 템플릿 좋아요 설정/취소
 */
export const setTemplateFavor = (body) => {
  return send("/store/setTemplateFavor.ax", body, { withAccessToken: true });
};

/**
 * 비슷한 템플릿 목록
 */
export const getSimilarTemplateList = (body) => {
  return send("/store/getSimilarTemplateList.ax", body, { withAccessToken: true });
};

/**
 * 템플릿/콤포넌트 코멘트 추가
 */
export const appendStoreComment = (body) => {
  return send("/store/appendStoreComment.ax", body, { withAccessToken: true });
};

/**
 * 템플릿/콤포넌트 코멘트 수정
 */
export const updateStoreComment = (body) => {
  return send("/store/updateStoreComment.ax", body, { withAccessToken: true });
};

/**
 * 템플릿/콤포넌트 코멘트 삭제
 */
export const deleteStoreComment = (body) => {
  return send("/store/deleteStoreComment.ax", body, { withAccessToken: true });
};

/**
 * 회원 가입유형 목록 요청
 */
export const getUserTypeSelect = (body) => {
  return send("/getUserTypeSelect.ax", body);
};

/**
 * 통계 대시보드 지표 얻기
 */
export const getMberEbookStat = (body) => {
  return send("/getMberEbookStat.ax", body, { withAccessToken: true });
};

/**
 * 통계 그래프 지표별 월별 지표수 데이터
 */
export const getMberStatInfos = (body) => {
  let formdata = new FormData();
  formdata.append("statType", body.statType);
  formdata.append("startDate", body.startDate);
  formdata.append("endDate", body.endDate);
  return send("/getMberStatInfos.ax", formdata, { withAccessToken: true });
};

/**
 * 통계 총조회수 월별 클릭시 이북 목록 얻기
 */
export const getStatViewCntListByMonth = (body) => {
  let formdata = new FormData();
  formdata.append("selDate", body.selDate);
  return send("/getStatViewCntListByMonth.ax", formdata, { withAccessToken: true });
};

/**
 * 통계 총좋아요수 월별 클릭시 이북 목록 얻기
 */
export const getStatLikeCntListByMonth = (body) => {
  let formdata = new FormData();
  formdata.append("selDate", body.selDate);  
  return send("/getStatLikeCntListByMonth.ax", formdata, { withAccessToken: true });
};

/**
 * 통계 총댓글수 월별 클릭시 이북 목록 얻기
 */
export const getStatCmtCntListByMonth = (body) => {
  let formdata = new FormData();
  formdata.append("selDate", body.selDate);    
  return send("/getStatCmtCntListByMonth.ax", formdata, { withAccessToken: true });
};

/**
 * 통계 총구독자수 월별 클릭시 이북 목록 얻기
 */
export const getStatSubCntListByMonth = (body) => {
  let formdata = new FormData();
  formdata.append("selDate", body.selDate);    
  return send("/getStatSubCntListByMonth.ax", formdata, { withAccessToken: true });
};


/**
 * 회원 로그인 체크 (직지솔루션)
 */
export const checkLoginFromJikji = (body) => {
  return send("/checkLoginFromJikji.ax", body);
};

/**
 * 해당 카테고리 컨텐츠 목록 얻기
 */
export const getSelCategoryContentsList = (body) => {
  let formdata = new FormData();
  formdata.append("cType", body.cType);
  return send("/ebook/getSelCategoryContentsList.ax", formdata);
};