import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useInfiniteScroll } from "../hook/useInfiniteScroll";
import { useContentData } from "../hook/useContentData";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import Container from "../components/Container";
import ThumbGrid from "../components/ThumbGrid";
import EbookContainer from "../components/EbookContainer";
import Spacer from "../components/Spacer";
import Loading from "../components/Loading";
import Hashtag from "../components/Hashtag";

export default function ContentList() {
  const location = useLocation();
  const ordering = location.state?.options?.ordering ?? 0;
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, contentData, hasMore } = useContentData(ordering, currentPage);
  const { lastElementRef } = useInfiniteScroll(isLoading, hasMore, setCurrentPage);

  return (
    <Layout>
      {isLoading ? <Loading /> : null}
      <Hashtag />
      <PageTitle
        title={
          !location.state.iconList & (ordering === 0)
            ? "최신 업로드"
            : !location.state.iconList & (ordering === 1)
            ? "인기 콘텐츠"
            : location.state.iconList & (ordering === 0)
            ? "최신순"
            : "인기순"
        }
        kind="moreEbooks"
        description={location.state.description}
      />

      <Spacer height={"0px"} />
      <Container>
        <EbookContainer>
          <ThumbGrid data={contentData} lastElementRef={lastElementRef} />
        </EbookContainer>
      </Container>
    </Layout>
  );
}
