import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import Hashtag from "../components/Hashtag";

import "../css/ContentGuide/main.css";
import "../css/ContentGuide/GlobalStyle.css";
import "../css/ContentGuide/font/pretendard.css";
import "../css/ContentGuide/kpub.css";
import Logo from "../images/pages/ContentGuide/logo.png";
import Img1_1 from "../images/pages/ContentGuide/1-1.png";
import Img1_2 from "../images/pages/ContentGuide/1-2.png";
import Img2 from "../images/pages/ContentGuide/2.png";
// import Img3 from "../images/pages/ContentGuide/3.png";
// import Img4 from "../images/pages/ContentGuide/4.png";
// import Img5 from "../images/pages/ContentGuide/5.png";
// import Img6 from "../images/pages/ContentGuide/6.png";
import Img7 from "../images/pages/ContentGuide/7.png";
import Img8 from "../images/pages/ContentGuide/8.png";
import Img9 from "../images/pages/ContentGuide/9.png";
import Img10 from "../images/pages/ContentGuide/10.png";
import Img11 from "../images/pages/ContentGuide/11.png";
import Img12 from "../images/pages/ContentGuide/12.png";
import Img13 from "../images/pages/ContentGuide/13.png";
import Img14 from "../images/pages/ContentGuide/14.png";
import Img15 from "../images/pages/ContentGuide/15.png";
import Img16 from "../images/pages/ContentGuide/16.png";
import Img17 from "../images/pages/ContentGuide/17.png";
import Img18 from "../images/pages/ContentGuide/18.png";
import Img19 from "../images/pages/ContentGuide/19.png";
import Img20 from "../images/pages/ContentGuide/20.png";
import Img21 from "../images/pages/ContentGuide/21.png";
import Img22 from "../images/pages/ContentGuide/22.png";
import Img23 from "../images/pages/ContentGuide/23.png";
import Img24 from "../images/pages/ContentGuide/24.png";
import Img25 from "../images/pages/ContentGuide/25.png";
import Img26 from "../images/pages/ContentGuide/26.png";
import Img27 from "../images/pages/ContentGuide/27.png";
import Img28 from "../images/pages/ContentGuide/28.png";
import Img29 from "../images/pages/ContentGuide/29.png";
import Img30 from "../images/pages/ContentGuide/30.png";
import Img31 from "../images/pages/ContentGuide/31.png";
import Img32 from "../images/pages/ContentGuide/32.png";
import Img33 from "../images/pages/ContentGuide/33.png";
import Img34 from "../images/pages/ContentGuide/34.png";
import CarouselContentGuide from "../components/CarouselContentGuide";
import { registerConsultInfo } from "../core/api";
import { useForm } from "react-hook-form";
import styled from "styled-components";
// import leftArrow from "../images/pages/ContentGuide/left-arr.png";
// import rightArrow from "../images/pages/ContentGuide/right-arr.png";

const ShowErrorInfo = styled.div`
  padding-top: 10px;
  p {
    color: ${(props) => props.color || "#2cd8ff"};
    font-family: "Pretendard";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
  }
`;

export default function ContentGuide() {
  const [personalbox, setPersonalbox] = useState(false);
  const [privacyAgree, setPrivacyAgree] = useState(false);
  const [marketingAgree, setMarketingAgree] = useState(false);

  const [privacyAgreeMo, setPrivacyAgreeMo] = useState(false);
  const [marketingAgreeMo, setMarketingAgreeMo] = useState(false);

  const [isOpen, setIsOpen] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [showButton, setShowButton] = useState("on");

  const phoneNumHandleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setInputValue(e.target.value);
    }
  };

  const wheelDirection = (event) => {
    if (event.wheelDelta > 0) {
      // console.log("scroll up");
      setShowButton("on");
    } else {
      // console.log("scroll down");
      setShowButton("off");
    }
  };

  const onScroll = (event) => {
    // console.log(window.scrollY);
    if(window.scrollY===0){
      setShowButton("on");
    }
  }
  /** mouse wheel 방향에 따라 업일때만 버튼이 보이도록 처리. 
   * 스크롤바를 직접 드래그할 경우에는 스크롤 위치가 0이 되면 버튼 보이도록 처리.
  */
  useEffect(() => {
    document.getElementById("root").addEventListener("wheel", wheelDirection, false);
    window.addEventListener("scroll", onScroll);
    return () => {
      document.getElementById("root").removeEventListener("wheel", wheelDirection, false);
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if (privacyAgree === true && marketingAgree === true) {
      setPersonalbox(true);
      setIsOpen("");
    } else if (privacyAgree === true && marketingAgree === false) {
      setPersonalbox(true);
    } else if (privacyAgree === false && marketingAgree === true) {
      setPersonalbox(false);
    } else if (privacyAgree === false && marketingAgree === false) {
      setPersonalbox(false);
    }
  }, [privacyAgree, marketingAgree]);

  useEffect(() => {
    if (inputValue.length === 10) {
      setInputValue(inputValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (inputValue.length === 13) {
      setInputValue(
        inputValue
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }

    if (inputValue.length > 13) {
      setInputValue(inputValue.substr(0, 13));
    }
  }, [inputValue]);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setError,
    reset,
    // getValues,
    // setValue,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    clearErrors: clearErrors2,
    formState: { errors: errors2 },
    setError: setError2,
    reset: reset2,
  } = useForm();

  const onValid_registConsultInfo = async (form) => {
    form.privacyAgree = form.privacyAgree ? "Y" : "N";
    form.marketingAgree = form.marketingAgree ? "Y" : "N";

    if (privacyAgree === false && marketingAgree === true) {
      return setError("privacyAgree", {
        message: "개인정보보호정책에 필수로 동의하셔야 합니다.",
      });
    } else if (privacyAgree === false && marketingAgree === false) {
      return setError("privacyAgree", {
        message: "개인정보보호정책에 필수로 동의하셔야 합니다.",
      });
    }

    var formdata = new FormData();

    formdata.append("companyName", form.companyName);
    formdata.append("phone", form.phone);
    formdata.append("privacyAgree", form.privacyAgree);
    formdata.append("marketingAgree", form.marketingAgree);

    const result = await registerConsultInfo(formdata);
    if (result) {
      const returnInt = result.returnInt;
      //console.log(result);
      if (returnInt === 1) {
        alert("상담신청이 접수되었습니다.^^");

        reset((formValues) => ({
          ...formValues,
          companyName: "",
          phone: setInputValue(""),
          personalbox: setPersonalbox(false),
          privacyAgree: setPrivacyAgree(false),
          marketingAgree: setMarketingAgree(false),
        }));
      } else if (returnInt === -1) {
        return setError("companyName", {
          message: "업제명(담당자명)을 2자 이상 입력하셔야 합니다.",
        });
      } else if (returnInt === -2) {
        return setError("phone", { message: "연락처를 모두 입력해 주세요." });
      } else if (returnInt === -3) {
        return setError("privacyAgree", {
          message: "개인정보보호정책에 필수로 동의하셔야 합니다.",
        });
      }
    }
  };

  const onValid_registConsultInfo_mobile = async (form) => {
    form.privacyAgreeMo = form.privacyAgreeMo ? "Y" : "N";
    form.marketingAgreeMo = form.marketingAgreeMo ? "Y" : "N";

    if (privacyAgreeMo === false && marketingAgreeMo === true) {
      return setError2("privacyAgreeMo", {
        message: "개인정보보호정책에 필수로 동의하셔야 합니다.",
      });
    } else if (privacyAgreeMo === false && marketingAgreeMo === false) {
      return setError2("privacyAgreeMo", {
        message: "개인정보보호정책에 필수로 동의하셔야 합니다.",
      });
    }

    var formdata = new FormData();

    formdata.append("companyName", form.companyNameMo);
    formdata.append("phone", form.phoneMo);
    formdata.append("privacyAgree", form.privacyAgreeMo);
    formdata.append("marketingAgree", form.marketingAgreeMo);

    const result = await registerConsultInfo(formdata);
    if (result) {
      const returnInt = result.returnInt;

      if (returnInt === 1) {
        alert("상담신청이 접수되었습니다.^^");

        reset2((formValues) => ({
          ...formValues,
          companyNameMo: "",
          phoneMo: setInputValue(""),
          privacyAgreeMo: setPrivacyAgreeMo(false),
          marketingAgreeMo: setMarketingAgreeMo(false),
        }));

        setTimeout(() => {
          setIsPopupOpen("");
          let bodyStyle = document.querySelector("body").style;
          bodyStyle.height = "initial";
          bodyStyle.overflow = "auto";
        }, 300);
      } else if (returnInt === -1) {
        return setError2("companyNameMo", {
          message: "업제명(담당자명)을 2자 이상 입력하셔야 합니다.",
        });
      } else if (returnInt === -2) {
        return setError2("phoneMo", {
          message: "연락처를 모두 입력해 주세요.",
        });
      } else if (returnInt === -3) {
        return setError2("privacyAgreeMo", {
          message: "개인정보보호정책에 필수로 동의하셔야 합니다.",
        });
      }
    }
  };

  //개인정보동의 팝업토글
  const openPersonal = (event) => {
    event.preventDefault();
    setIsOpen(isOpen === "" ? "open" : "");
  };
  //팝업닫기
  const closePersonal = (event) => {
    event.preventDefault();
    setIsOpen("");
  };

  //모바일 비용문의 팝업
  const openPopup = (event) => {
    event.preventDefault();
    setIsPopupOpen("open");
    let bodyStyle = document.querySelector("body").style;
    bodyStyle.height = "100%";
    bodyStyle.overflow = "hidden";
  };

  //모바일 비용문의 팝업닫기
  const closePopup = (event) => {
    event.preventDefault();
    setIsPopupOpen("");
    let bodyStyle = document.querySelector("body").style;
    bodyStyle.height = "initial";
    bodyStyle.overflow = "auto";
  };

  return (
    <Layout>
      <Hashtag />
      {/* <section className={`section1 ${showButton}`}>
        <div className="container">
          <div className="btn-wrap">
            <Link to="/template" className="btn shadow" alt="템플릿">
              템플릿
            </Link>
            <Link to="/request" className="btn shadow">
              제작 서비스
            </Link>
            <div className="display-mo">
              <Link to="/download" className="btn shadow">
                직지 다운로드{" "}
                <img className="logo" src={Logo} alt="직지 로고" />{" "}
              </Link>
            </div>
          </div>
        </div>
      </section> */}
      <section className="section2">
        <div className="container">
          <div className="content-wrap">
            <div className="left">
              <div className="ltop">퍼블리셔가 필요없는 저작도구 직지</div>
              <h1>
                PPT만 사용할 줄 알면
                <br /> <strong>편집부터 배포까지</strong>
                <br className="display-mo" /> 원스톱으로!
              </h1>
              <p className="">
                개발자에게 부탁해야지만 가능했던 다양한 효과를
                <br /> 드래그앤드롭으로 간단하게 사용해보세요.
              </p>
              <div className="btn-wrap">
                <Link to="https://kpub.co.kr/jikji_download/Jik-ji%20Editor%20setup%20(win%20x86).exe" className="btn btn-r">
                저작도구 다운로드
                </Link>
{/* 기존 무료 체험하기와 솔루션 문의하기 버튼을 직지 시작하기 버튼 하나로 정리, 2024.12.26
                <Link to="/download" className="btn btn-r">
                  직지 시작하기
                </Link>
                <Link to="/solution" className="btn btn-w">
                  솔루션 문의
                </Link>
*/}
              </div>
            </div>
            <div className="video-wrap">
              <img src={Img1_1} alt="직지소개 동영상" />
              <Link
                to={`https://www.youtube.com/watch?v=bD5tB76nHtE&t=61s`}
                target="_blank"
                className="yt-link"
              >
                <img src={Img1_2} alt="직지소개 동영상" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section3">
        <div className="container">
          <div className="content-wrap">
            <div className="text-wrap">
              <div className="tit">
                디자인만 하세요.
                <br className="display-mo" /> 나머지는 <span>직지</span>가
                할께요.
              </div>
              <p className="">
                필요한 템플릿이나 기능은 요청하시면 언제든 지원해 드립니다.
              </p>
            </div>
            <div className="img-wrap display-pc">
              <img src={Img2} alt="직지 아이콘" />
            </div>
            <div className="img-wrap display-mo ad">
              <img src={Img2} alt="직지 아이콘" />
            </div>
          </div>
        </div>
      </section>
      <section className="section4">
        <div className="container">
          <div className="content-wrap">
            <div className="tit">
              직지에서 제공하는 다양한 템플릿 디자인으로
              <br className="display-mo" /> 전자책 및 웹콘텐츠를 제작 할 수
              있어요.
            </div>
            <CarouselContentGuide />
            <div className="content">
              <div className="">
                <h2>
                  맞춤 템플릿으로
                  <br /> 줄어드는 작업시간
                </h2>
                <p className="">
                  반복되는 화면은 템플릿으로 저장하여,
                  <br /> 작업시간을 획기적으로 줄일 수 있습니다.
                </p>
                <div className="btn-wrap">
                  <Link to="/template" className="btn btn-r">
                    템플릿으로 바로가기
                  </Link>
                </div>
              </div>
              <div className="img-wrap">
                <img src={Img7} alt="템플릿 이미지" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section5">
        <div className="container">
          <div className="content-wrap">
            <div className="content">
              <div className="list">
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img8} alt="전자책" />
                  </div>
                  <div className="list-name">전자책</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img9} alt="전자앨범" />
                  </div>
                  <div className="list-name">전자앨범</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img10} alt="리플랫" />
                  </div>
                  <div className="list-name">리플랫</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img11} alt="회사소개" />
                  </div>
                  <div className="list-name">회사소개</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img12} alt="전자교안" />
                  </div>
                  <div className="list-name">전자교안</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img13} alt="전자카달로그" />
                  </div>
                  <div className="list-name">전자카달로그</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img14} alt="랜딩페이지" />
                  </div>
                  <div className="list-name">랜딩페이지</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img15} alt="사이트" />
                  </div>
                  <div className="list-name">사이트</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img16} alt="뉴스레터" />
                  </div>
                  <div className="list-name">뉴스레터</div>
                </div>
              </div>
              <div className="right">
                <h2>
                  PDF만 있어도
                  <br /> 다양한 포맷으로 제작 가능
                </h2>
                <p className="">
                  PDF를 손쉽게 변환하는 편리한 솔루션을 제공합니다.
                  <br /> 변환 후 웹으로 바로 출판이 가능합니다.
                </p>
                <div className="btn-wrap">
                <Link to="https://kpub.co.kr/jikji_download/Jik-ji%20Editor%20setup%20(win%20x86).exe" className="btn btn-r">
                저작도구 다운로드
                </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section6">
        <div className="container">
          <div className="content-wrap">
            <div className="content">
              <div className="left">
                <h2>맞춤 제작 서비스</h2>
                <p className="">
                  원하시는 웹콘텐츠를 전문적인 상담을 통해
                  <br /> 맞춤형으로 제작해 드립니다.
                </p>
                <div className="btn-wrap">
                  <Link to="/request" className="btn btn-r">
                    제작 문의
                  </Link>
                </div>
              </div>
              <div className="list">
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img17} alt="전자교과서" />
                  </div>
                  <div className="list-name">전자교과서</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img18} alt="회사소개서" />
                  </div>
                  <div className="list-name">회사소개서</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img19} alt="전자카달로그" />
                  </div>
                  <div className="list-name">전자카달로그</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img20} alt="스마트교안" />
                  </div>
                  <div className="list-name">스마트교안</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img21} alt="전자앨범" />
                  </div>
                  <div className="list-name">전자앨범</div>
                </div>
                <div className="list-item">
                  <div className="img-wrap">
                    <img src={Img22} alt="전자동화책" />
                  </div>
                  <div className="list-name">전자동화책</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section7">
        <div className="container">
          <div className="content-wrap">
            <div className="content">
              <div className="img-wrap">
                <img src={Img23} alt="디스플레이 셈플 이미지" />
              </div>
              <div className="right">
                <h2>
                  모바일, 태블릿, 데스크탑
                  <br /> 모두 완벽지원
                </h2>
                <p className="">
                  직지솔루션에서 작업을 하면 한 번에
                  <br /> 다양한 디바이스에 콘텐츠를 배포할 수 있습니다.
                </p>
                <div className="btn-wrap">
                  <Link to="/request" className="btn btn-r">
                  제작 문의
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section8">
        <div className="container">
          <div className="content-wrap">
            <div className="content">
              <div className="left">
                <h2>
                  100MB 무료 저장
                  <br className="display-mo" /> 서비스 제공
                </h2>
                <p className="">
                  100MB 까지 무료로 사용하세요.
                  <br /> 바로 나만의 사이트가 완성됩니다.
                </p>
                {/* <div className="btn-wrap">
                  <Link to="/download" className="btn btn-r">
                    바로 시작하기
                  </Link>
                </div> */}
              </div>
              <div className="img-wrap">
                <img src={Img24} alt="DB 셈플 이미지" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section9">
        <div className="container">
          <div className="content-wrap">
            <div className="content">
              <h3>
                직지솔루션과
                <br className="display-mo" /> 함께하는 고객사
              </h3>
              <p className="">
                15년간 교육용 콘텐츠 제작 부터
                <br className="display-mo" /> 전국 학교 디지털교과서
                뷰어 선정까지
                <br /> 다양한 콘텐츠 제작경험과 탁월한 기술을 토대로 만들어진
                <br className="display-mo" /> 직지솔루션은 정부기관 및 교육
                선도기업들이 이용하고 있습니다.
              </p>
            </div>
            <div className="list">
              <div className="img-wrap">
                <img src={Img25} alt="교육부 로고" />
              </div>
              <div className="img-wrap">
                <img src={Img26} alt="국립특수교육원 로고" />
              </div>
              <div className="img-wrap">
                <img src={Img27} alt="한국교육학술정보원 로고" />
              </div>
              <div className="img-wrap">
                <img src={Img28} alt="microsoft 로고" />
              </div>
              <div className="img-wrap">
                <img src={Img29} alt="천재교육 로고" />
              </div>
              <div className="img-wrap">
                <img src={Img30} alt="interpark 로고" />
              </div>
              <div className="img-wrap">
                <img src={Img31} alt="visang 로고" />
              </div>
              <div className="img-wrap">
                <img src={Img32} alt="miraeN 로고" />
              </div>
              <div className="img-wrap">
                <img src={Img33} alt="금성출판사 로고" />
              </div>
              <div className="img-wrap">
                <img src={Img34} alt="multicampus 로고" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <form onSubmit={handleSubmit(onValid_registConsultInfo)}>
        <section className="section10 display-pc">
          <div className="container">
            <div className="content-wrap">
              <div className="text">
                전자카탈로그, 소식지, 뉴스레터, 매뉴얼을 만들려는 일반기업 및
                차시등 교과서 제작업체를 위한 솔루션을 전문적으로 제공합니다.
              </div>
              <div className="subtext">
                전화상담 후 솔루션 구독시 특별한 혜택을 드립니다.
              </div>
              <div className="ipt-wrap">
                <input
                  onInput={() => {
                    clearErrors();
                  }}
                  {...register("companyName", {
                    required: {
                      value: true,
                      message: "업체명(담당자명)을 입력하세요.",
                    },
                    maxLength: {
                      value: 20,
                      message: "업체명(담당자명)은 최대 20글자 이하입니다.",
                    },
                    minLength: {
                      value: 2,
                      message: "업체명(담당자명)은 최소 2글자 이상입니다.",
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9가-힣]{2,20}$/,
                      message:
                        "업체명(담당자명)은 공백과 특수문자를 제외한 2~20글자 입니다.",
                    },
                  })}
                  type="text"
                  placeholder="업체명(담당자명)을 입력하세요."
                />
                <input
                  onInput={() => {
                    clearErrors();
                  }}
                  {...register("phone", {
                    required: {
                      value: true,
                      message: "유효한 연락처를 입력하세요.",
                    },
                    maxLength: {
                      value: 13,
                      message: "연락처는 최대 13글자 이하입니다.",
                    },
                    minLength: {
                      value: 10,
                      message: "연락처는 최소 10글자 이상입니다.",
                    },
                  })}
                  type="text"
                  placeholder="연락처를 입력하세요."
                  onChange={phoneNumHandleChange}
                  value={inputValue}
                  autoComplete="on"
                />
                <button className="btn" type="submit">
                  비용 문의하기
                </button>
              </div>
              <div className="checkbox-wrap">
                <input
                  type="checkbox"
                  name="personalbox"
                  style={{ cursor: "pointer" }}
                  onClick={openPersonal}
                  checked={personalbox}
                  onChange={() => {}}
                  id="personalbox"
                />
                <label
                  htmlFor="personalbox"
                  onClick={openPersonal}
                  style={{ cursor: "pointer" }}
                >
                  개인정보 수집 이용 및 마케팅 활용 전체 동의하기
                </label>
              </div>
              <ShowErrorInfo>
                <p>
                  {errors?.companyName ? (
                    <em>{errors.companyName.message}</em>
                  ) : null}
                </p>
                <p>{errors?.phone ? <em>{errors.phone.message}</em> : null}</p>
                <p>
                  {errors?.privacyAgree ? (
                    <em>{errors.privacyAgree.message}</em>
                  ) : null}
                </p>
              </ShowErrorInfo>
              <div className={`popup-wrapper-personal ${isOpen}`} id="pcpopup">
                <div className="popup-personal">
                  <div className="btn-wrap">
                    <Link className="btn-close" onClick={closePersonal}></Link>
                  </div>
                  <div className="checkbox-wrap">
                    <div className="with-label">
                      <input
                        {...register("privacyAgree", {
                          required: {
                            value: true,
                            message:
                              "(필수)개인정보보호정책에 동의하셔야 합니다.",
                          },
                          // onChange:() => setPrivacyAgree(!privacyAgree)
                        })}
                        checked={!!privacyAgree}
                        onChange={() => setPrivacyAgree(!privacyAgree)}
                        type="checkbox"
                        id="privacyAgree"
                      />
                      <label htmlFor="privacyAgree">
                        (필수) 개인정보보호정책에 동의합니다.
                      </label>
                    </div>
                    <div className="subcheck">(목적) 가격문의 답변회신</div>
                    <div className="subcheck">(항목) 업체명, 전화번호</div>
                    <div className="subcheck">
                      (보유 및 이용기간) 최초 동의 시로부터 2년마다 동의 여부
                      확인
                    </div>
                  </div>
                  <div className="checkbox-wrap">
                    <div className="with-label">
                      <input
                        {...register("marketingAgree")}
                        checked={!!marketingAgree}
                        onChange={() => setMarketingAgree(!marketingAgree)}
                        type="checkbox"
                        id="marketingAgree"
                      />
                      <label htmlFor="marketingAgree">
                        (선택) 마케팅 수신 동의
                      </label>
                    </div>
                    <div className="subcheck">
                      토브소프트에서 진행하는 무료 이벤트 소식 받아보세요!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
      <section className="section10 display-mo">
        <div className="btn-wrap">
          <Link className="btn" onClick={openPopup}>
            {" "}
            비용 문의하기
          </Link>
        </div>
      </section>
      <form onSubmit={handleSubmit2(onValid_registConsultInfo_mobile)}>
        <div className={`popup-wrapper ${isPopupOpen}`} id="popup">
          <div className="popup">
            <div className="btn-wrap">
              <Link className="btn-close" onClick={closePopup}></Link>
            </div>
            <div className="tit">
              전자카탈로그, 소식지, 뉴스레터, 매뉴얼을 만들려는 일반기업 및
              <br />
              차시등 교과서 제작업체를 위한
              <br />
              솔루션을 전문적으로 제공합니다.
            </div>
            <p>전화상담 후 솔루션 구독시 특별한 혜택을 드립니다.</p>
            <div className="ipt-wrap">
              <input
                onInput={() => {
                  clearErrors2();
                }}
                {...register2("companyNameMo", {
                  required: {
                    value: true,
                    message: "업체명(담당자명)을 입력하세요.",
                  },
                  maxLength: {
                    value: 20,
                    message: "업체명(담당자명)은 최대 20글자 이하입니다.",
                  },
                  minLength: {
                    value: 2,
                    message: "업체명(담당자명)은 최소 2글자 이상입니다.",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9가-힣]{2,20}$/,
                    message:
                      "업체명(담당자명)은 공백과 특수문자를 제외한 2~20글자 입니다.",
                  },
                })}
                type="text"
                placeholder="업체명(담당자명)을 입력하세요."
              />
              <input
                onInput={() => {
                  clearErrors2();
                }}
                {...register2("phoneMo", {
                  required: {
                    value: true,
                    message: "유효한 연락처를 입력하세요.",
                  },
                  maxLength: {
                    value: 13,
                    message: "연락처는 최대 13글자 이하입니다.",
                  },
                  minLength: {
                    value: 10,
                    message: "연락처는 최소 10글자 이상입니다.",
                  },
                })}
                type="text"
                placeholder="연락처를 입력하세요."
                onChange={phoneNumHandleChange}
                value={inputValue}
              />
            </div>
            <div className="checkbox-wrap">
              <div className="with-label">
                <input
                  {...register2("privacyAgreeMo", {
                    required: {
                      value: true,
                      message: "(필수)개인정보보호정책에 동의하셔야 합니다.",
                    },
                  })}
                  checked={!!privacyAgreeMo}
                  onChange={() => setPrivacyAgreeMo(!privacyAgreeMo)}
                  type="checkbox"
                  id="privacyAgreeMo"
                />
                <label htmlFor="privacyAgreeMo">
                  (필수) 개인정보보호정책에 동의합니다.
                </label>
              </div>
              <div className="subcheck">(목적) 가격문의 답변회신</div>
              <div className="subcheck">(항목) 업체명, 전화번호</div>
              <div className="subcheck">
                (보유 및 이용기간) 최초 동의 시로부터 2년마다 동의 여부 확인
              </div>
            </div>
            <div className="checkbox-wrap">
              <div className="with-label">
                <input
                  {...register2("marketingAgreeMo")}
                  checked={!!marketingAgreeMo}
                  onChange={() => setMarketingAgreeMo(!marketingAgreeMo)}
                  type="checkbox"
                  id="marketingAgreeMo"
                />
                <label htmlFor="marketingAgreeMo">
                  (선택) 마케팅 수신 동의
                </label>
              </div>
              <div className="subcheck">
                토브소프트에서 진행하는 무료 이벤트 소식 받아보세요!
              </div>
            </div>
            <div className="btn-wrap b-btn">
              <button className="btn" type="submit">
                비용 문의하기
              </button>
            </div>
            <ShowErrorInfo color="#0000ff">
              <p>
                {errors2?.companyNameMo ? (
                  <em>{errors2.companyNameMo.message}</em>
                ) : null}
              </p>
              <p>
                {errors2?.phoneMo ? <em>{errors2.phoneMo.message}</em> : null}
              </p>
              <p>
                {errors2?.privacyAgreeMo ? (
                  <em>{errors2.privacyAgreeMo.message}</em>
                ) : null}
              </p>
            </ShowErrorInfo>
          </div>
        </div>
      </form>
    </Layout>
  );
}
