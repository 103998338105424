import { useEffect, useState } from "react";
import { getEbookSearch } from "../core/api";
import { getRequestBody, removeDuplicates } from "../util";

export function useSearchData(searchText, page) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [ebookData, setEbookData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setEbookData([]);
    setUserData([]);
  }, [searchText]);

  useEffect(() => {
    async function getSearchData(searchBody) {
      try {
        const res = await getEbookSearch(searchBody);

        if (res.returnInt === 1) {
          // TODO: 더 효율적으로 중복 없이 배열에 추가하도록 개선
          setEbookData((old) => removeDuplicates([...old, ...res.row.searchList], (a, b) => a.eSeq === b.eSeq));
          setUserData((old) => removeDuplicates([...old, ...res.row.userList], (a, b) => a.userId === b.userId));
          setHasMore(res.row.totalCnt > page);
        } else {
          setHasMore(false);
          setError(true);
        }
      } catch (err) {
        setError(err);
      }

      setIsLoading(false);
    }

    const searchBody = getRequestBody({ searchText, page });

    setIsLoading(true);
    setError(false);
    getSearchData(searchBody);
  }, [searchText, page]);

  return { isLoading, error, ebookData, userData, hasMore };
}
