import { useState } from "react";
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import prevArrow from "../images/components/carousel/prevArrow.svg";
import nextArrow from "../images/components/carousel/nextArrow.svg";
import banner_1 from "../images/components/carousel/banner_1.png";
import banner_2 from "../images/components/carousel/banner_2.png";
import banner_3 from "../images/components/carousel/banner_3.png";
import banner_4 from "../images/components/carousel/banner_4.png";
import banner_5 from "../images/components/carousel/banner_5.png";
import banner_m_1 from "../images/components/carousel/banner_m_1.png";
import banner_m_2 from "../images/components/carousel/banner_m_2.png";
import banner_m_3 from "../images/components/carousel/banner_m_3.png";
import banner_m_4 from "../images/components/carousel/banner_m_4.png";
import banner_m_5 from "../images/components/carousel/banner_m_5.png";
import icon1 from "../images/pages/guide/icon1.svg";
import icon2 from "../images/pages/guide/icon2.svg";
import icon3 from "../images/pages/guide/icon3.svg";

const Slick = styled(Slider)`
  width: 1122px;
  height: 504px;
  margin: ${(props) =>
    props.page === "download" ? "0 auto 144px" : "0 auto 240px"};
  background: #ffffff;
  .arrow {
    position: absolute;
    top: 50%;
    width: 48px;
    height: 48px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    &.next {
      right: 0;
      transform: translateY(-50%) translateX(50%);
      background: rgba(0, 0, 0, 0.5) url(${nextArrow}) center/100% no-repeat;
    }
    &.prev {
      left: 0;
      transform: translateY(-50%) translateX(-50%);
      background: rgba(0, 0, 0, 0.5) url(${prevArrow}) center/100% no-repeat;
    }
  }
  .slick-list {
    height: 100%;
    border: 1px solid #c5c7cc;
    border-radius: 10px;
    .card {
      position: relative;
      width: 100%;
      height: 504px;
      border-radius: 10px;

      img.banner {
        width: 100%;
        height: 504px;
        /* margin: 40px auto; */
      }
      > span {
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translateX(-50%);
        padding: 8px 16px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        background: rgba(0, 0, 0, 0.6);
        /* background-color: #ffffff; */
        em {
          font-family: "pretendard-SemiBold";
        }
      }
      &.btnBox {
        display: inline-flex !important;
        justify-content: center;
        align-items: center;
        gap: 20px;
        /* border: 1px solid #c5c7cc; */
        div.btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 320px;
          height: 240px;

          box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
          border-radius: 10px;
          &:nth-child(1) {
            background: #ebf8ff;
            border: 2px solid #32aafa;
          }
          &:nth-child(2) {
            background: #fffadb;
            border: 2px solid #ffa914;
          }
          &:nth-child(3) {
            background: #fff1f0;
            border: 2px solid #ff4733;
          }
          img {
            width: 56px;
            height: 56px;
            margin: 60px 0 44px;
          }
          p {
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            color: #4b4c4e;

            &:last-of-type {
              font-family: "pretendard-SemiBold";
            }
          }
        }
      }
    }
  }
  .slick-dots {
    display: flex !important;
    justify-content: center;
    gap: 16px;
    align-items: center;
    top: 0;
    height: 44px;
    li {
      position: static;
      display: block;
      width: 12px;
      height: 12px;
      margin: 0;
      button {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #c4c6cc;
        border: 1px solid #262626;
        opacity: 0.4;
        &:before {
          content: "";
        }
      }
      &.slick-active button {
        background: #ffffff;
        opacity: 1;
      }
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: calc(100% - 40px);
    height: auto;
    aspect-ratio: 100/100;
    margin-bottom: ${(props) => (props.page === "download" ? "30px" : "144px")};
    .arrow {
      width: 40px;
      height: 40px;
    }
    .slick-list {
      .slick-track {
        height: 100%;
        .card {
          height: 100%;
          img.banner {
            width: 100%;
            height: 100%;
          }
          > span {
            padding: 6px 12px;
            font-size: 15px;
            line-height: 18px;
            white-space: nowrap;
          }
          &.btnBox {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            div.btn {
              flex-direction: row;
              width: 70%;
              height: auto;
              aspect-ratio: 210/54;
              gap: 10px;

              img {
                margin: 0 0 0 5%;
                width: 32px;
                height: 32px;
              }
              .p-wrap p {
                font-size: 14px;
                line-height: 19px;
                text-align: justify;
              }
            }
          }
        }
      }
    }
  }
`;

export default function Carousel({ page }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  const handleBeforeChange = (current, next) => {
    setCurrentSlide(next);
  };

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow currentSlide slideCount />,
    prevArrow: <PrevArrow currentSlide />,
  };

  const onWebLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Slick {...settings} beforeChange={handleBeforeChange} page={page}>
      <div className="card">
        <img src={!tablet ? banner_1 : banner_m_1} alt="" className="banner" />
        <span>
          설치한 <em>‘직지 저작도구’</em>를 실행합니다.{" "}
        </span>
      </div>
      <div className="card">
        <img src={!tablet ? banner_2 : banner_m_2} alt="" className="banner" />
        <span>
          {page === "download" && "직지 회원가입 혹은 "}
          <em>KPUB계정</em>으로 로그인
        </span>
      </div>
      <div className="card">
        <img src={!tablet ? banner_3 : banner_m_3} alt="" className="banner" />
        <span>
          프로젝트에서 <em>‘새로 만들기’</em> 클릭
        </span>
      </div>
      <div className="card">
        <img src={!tablet ? banner_4 : banner_m_4} alt="" className="banner" />
        <span>
          원하는 형식으로 <em>프로젝트</em> 시작하기
        </span>
      </div>
      <div className="card">
        <img src={!tablet ? banner_5 : banner_m_5} alt="" className="banner" />
        <span>
          쉽고 빠르게 <em>콘텐츠</em> 제작하기
        </span>
      </div>
      <div className="card btnBox">
        <div
          className="btn"
          onClick={() => onWebLink("https://me2.do/FpM308YM")}
        >
          <img src={icon1} alt="" className="link" />
          <div className="p-wrap">
            <p>직지 사용자 매뉴얼</p>
            <p>바로가기</p>
          </div>
        </div>
        <div
          className="btn"
          onClick={() => onWebLink("http://tovsoft.com:8080/jikji/tutorial")}
        >
          <img src={icon2} alt="" className="link" />
          <div className="p-wrap">
            <p>따라하며 배우는 튜토리얼</p>
            <p>바로가기</p>
          </div>
        </div>
        <div
          className="btn"
          onClick={() =>
            onWebLink(
              "https://www.youtube.com/playlist?list=PLONYAo8uJT6zm7lEXOGVDoeLNC4vv124S"
            )
          }
        >
          <img src={icon3} alt="" className="link" />
          <div className="p-wrap">
            <p>직지 저작도구 동영상 강의</p>
            <p>바로가기</p>
          </div>
        </div>
      </div>
    </Slick>
  );
}

function NextArrow({ onClick, currentSlide, slideCount }) {
  return (
    currentSlide !== slideCount - 1 && (
      <div className="arrow next" onClick={onClick}></div>
    )
  );
}
function PrevArrow({ onClick, currentSlide }) {
  return (
    currentSlide !== 0 && <div className="arrow prev" onClick={onClick}></div>
  );
}
