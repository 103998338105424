import { Link } from "react-router-dom";
import styled from "styled-components";
import ThumbGridLine2 from "./ThumbGridLine2";
import Arrow_more from "../images/components/EbookList/arrow_more.svg";

const Wraper = styled.section`
  width: 100%;
  margin: 0px auto 80px;

  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 72px;
  }
`;

const TitleLine = styled.div`
  width: 100%;
  margin-bottom: 0px;
  padding: 0px 15px;

  /* border: 1px solid red; */
  /* margin-bottom: 24px; */
  /* @media screen and (max-width: 1280px) {
  } */
  h2 {
    margin-bottom: 4px;
    font-family: "Pretendard-SemiBold";
    font-size: 32px;
    line-height: 38px;
    color: #303441;
  }
  > div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    h3 {
      font-family: "Pretendard-Medium";
      font-size: 18px;
      line-height: 22px;
      color: #727585;
    }
  }
  span.more {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 14px;
    line-height: 17px;
    padding: 0;
    border: none;
    background: none;
    color: #303441;
    cursor: pointer;
    .icon {
      width: 5px;
      height: 10px;
      background: url(${Arrow_more}) center/100% no-repeat;
      margin-left: 5px;
    }
    &:hover .icon {
      transform: scale(1.1);
    }
    @media ${({ theme }) => theme.device.tablet} {
      font-size: 12px;
      .icon {
        margin-left: 5px;
        transform: scale(0.9);
      }
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    h2 {
      margin-bottom: 2px;
      font-size: 26px;
      line-height: 31px;
    }
    > div h3 {
      font-size: 14px;
      line-height: 17px;
    }
    span.more {
      font-size: 12px;
      line-height: 14px;
      .icon {
        width: 4px;
        height: 8px;
        background: url(${Arrow_more}) center/100% no-repeat;
        margin-left: 4px;
      }
    }
  }
`;

export default function EbookListLine2({
  title,
  data,
  name,
  needFilter,
  linkTo,
  options,
  description,
  moreContents,
}) {
  return (
    <Wraper>
      <ThumbGridLine2 data={data} row title={title} />
    </Wraper>
  );
}