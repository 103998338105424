import { Link, useNavigate, useParams } from "react-router-dom";
import S from "./EbookDetail.styled";
import { useQuery } from "@tanstack/react-query";
import {
  confirmLogin,
  getThumbnailColor,
  useIsLogin,
  useRenewToken,
  useScale,
  useUser,
} from "../util";
import { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import BlankBox from "../components/BlankBox";
import { useRecoilState } from "recoil";
import { mobileCommentsAtom, modalState, scrollBtnState } from "../atoms";
import Container from "../components/Container";
import { useMediaQuery } from "react-responsive";
import MobileComments from "../components/MobileComments";
import TitleLine from "../components/TitleLine";
import Information from "../components/Information";
import CCL from "../images/pages/EbookDetail/CCL.svg";
import Views from "../images/pages/EbookDetail/views.svg";
import Hearts from "../images/pages/EbookDetail/heart_black.svg";
import Heart_off from "../images/pages/EbookDetail/heart_off.svg";
import Heart_on from "../images/pages/EbookDetail/heart_on.svg";
import Heart_hover from "../images/pages/EbookDetail/heart_hover.svg";
import Share_off from "../images/pages/EbookDetail/ios_share_off.svg";
import Share_on from "../images/pages/EbookDetail/ios_share_on.svg";
import Complain_off from "../images/pages/EbookDetail/complain_off.svg";
import Complain_on from "../images/pages/EbookDetail/complain_on.svg";
import Communication from "../images/pages/EbookDetail/communication.svg";
import Kakao from "../images/pages/EbookDetail/Kakao.svg";
import KakaoStory from "../images/pages/EbookDetail/icon-kakaostory.png";
// import LINE from "../images/pages/EbookDetail/LINE.svg";
import Facebook from "../images/pages/EbookDetail/Facebook.svg";
import Twitter from "../images/pages/EbookDetail/Twitter.svg";
import Instargram from "../images/pages/EbookDetail/Instargram.svg";
import MoreNavi from "../images/pages/EbookDetail/icon-more.png";
import Modal from "../components/Modal";
import Notice from "../components/Notice";
import Aside from "../components/Aside";
import {
  getEbookDetail,
  getSimilarEbookList,
  setEbookBookmark,
  setEbookFavor,
} from "../core/api";
import { getCookie } from "../core/cookie";
import CommentsSection from "../components/CommentsSection";
import Loading from "../components/Loading";
import { useSubscribe, useUnSubscribe } from "../hook/useSubscribe";
// CCL
import ccby from "../images/ccl/Cc-by.svg";
import ccnc from "../images/ccl/Cc-nc.svg";
import ccnd from "../images/ccl/Cc-nd.svg";
import ccsa from "../images/ccl/Cc-sa.svg";
import cclogo from "../images/ccl/Cc-logo.svg";

const shareKakao = (title) => {
  // @ts-ignore
  if (window?.Kakao) {
    // @ts-ignore
    const kakao = window.Kakao;
    if (!kakao.isInitialized()) {
      kakao.init('468b13679c564b92cdc6f204818c702f');
      // kakao.init('e93a80654d235a38033b6c9a0602ecf5');
    }

    kakao.Link.sendDefault({
      objectType: 'text',
      text: title, // 인자값으로 받은 title
      // description: '설명', // 인자값으로 받은 title
      // imageUrl: '',
      link: {
        mobileWebUrl: window?.location?.href, // 인자값으로 받은 window?.location?.href(uri 형태)
        webUrl: window?.location?.href
      },
      buttons: [
        {
          title: '바로가기',
          link: {
            mobileWebUrl: window?.location?.href,
            webUrl: window?.location?.href
          }
        }
      ]
    });
  }
};

const shareDotdotdot = async (
  title,
  url,
  onCopy
) => {
  const isSupportedShare = !!navigator?.share;
  if (isSupportedShare) {
    await navigator.share({
      title: title,
      url: url
    });
  } else {
    if (onCopy) {
      onCopy();
    }
  }
};

const shareKakakoStory = (title, url) => {
  // @ts-ignore
  if (window?.Kakao) {
    // @ts-ignore
    const kakao = window.Kakao;
    if (!kakao.isInitialized()) {
      kakao.init('468b13679c564b92cdc6f204818c702f');
    }

    kakao.Story.share({
      url: url,
      text: title
    });
  }
};

const shareTwit = (title, url) => {
  window.open(`https://twitter.com/intent/tweet?text=${title}&url=${url}`);
};

export default function EbookDetail() {
  // const { user } = useUser();
  const { user, accessToken } = useUser();
  const { id } = useParams();
  let formdata = new FormData();
  formdata.append("eSeq", id);
  const navigate = useNavigate();

  // 토큰 만료일경우 api에서 토큰 갱신을 하므로 갱신이 되면 refetch 되도록 queryKey에 accessToken을 추가
  const {
    data,
    refetch: detailRefetch,
    isLoading,
  } = useQuery(["detail", id, accessToken], () =>
    getEbookDetail(formdata, (errorMsg) => {
      alert(errorMsg);
      navigate("/");
    })
  );
  const detailData = data?.row;
  const thumbUrl = detailData?.thumbUrl ?? "";
  const nickName = detailData?.nickName ?? "";
  const mSeq = detailData?.mSeq ?? "";
  const coverUrl = detailData?.coverUrl ?? "";
  const bookTitle = detailData?.bookTitle ?? "";
  const bookDesc = detailData?.bookDesc ?? "";
  
  const cclTf    = detailData?.cclTf??0;
  const profitTf = detailData?.profitTf??0;
  const changeTf = detailData?.changeTf??0;

  const isSubscribe = detailData?.isSubscribe ?? "";
  const [thumbColor, setThumbColor] = useState("");
  const [isPop, setIsPop] = useState(false);
  const {
    data: similarData,
    refetch: similarRefetch,
    isLoading: similarLoading,
  } = useQuery(["similar", id, accessToken], () =>
    getSimilarEbookList(formdata)
  );
  const [isFavor, setIsFavor] = useState(false);
  const [scollBtn, setScollBtn] = useRecoilState(scrollBtnState);
  const [isBookMarked, setIsBookmarked] = useState(false);
  const [complain, setComplain] = useState(true);
  const [isOpen, setIsOpen] = useRecoilState(modalState);
  const [isNotice, setIsNotice] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [mobileComments, setMobileComments] =
    useRecoilState(mobileCommentsAtom);
  const body = document.querySelector("body");
  // 반응형
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });
  const mobileLg = useMediaQuery({ query: `(max-width: 540px)` });
  const mobile = useMediaQuery({ query: `(max-width: 480px)` });
  const mobileBg = useScale(480);

  //구독관련
  const { requestScribe, responseMessage, setResponseMessage } = useSubscribe();
  const { requestUnScribe, responseMessageUs, setResponseMessageUs } =
    useUnSubscribe();

  useEffect(() => {
    // detailRefetch();
    // similarRefetch();
    setTimeout(() => {
      setScollBtn(false);
    }, 10);

    setMobileComments(false);
    checkBookmark();
    checkFavor();
    checkCommentList();
  }, [id]);

  useEffect(() => {
    // detailRefetch();
  }, [isBookMarked, isFavor]);

  useEffect(() => {
    // detailRefetch();
    setThumbColor(getThumbnailColor(mSeq));
    checkBookmark();
    checkFavor();
    checkCommentList();

    // console.log("구독중?", isSubscribe);
  }, [data]);

  const onWebLink = (url) => {
    const cookie = getCookie("ACCESSTOKEN");
    const _url = cookie ? `${url}?token=${cookie}` : url;
    window.open(_url, "_blank");
  };

  const checkBookmark = () => {
    detailData?.isBookmark == 1
      ? setIsBookmarked(true)
      : setIsBookmarked(false);
  };
  const checkFavor = () => {
    detailData?.isFavor === 1 ? setIsFavor(true) : setIsFavor(false);
  };
  const checkCommentList = () => {
    if (detailData) {
      const arr = detailData?.commentList;
      const reverse = [...arr].reverse();
      setCommentList(reverse);
    }
  };

  const addBookmark = async () => {
    if (isBookMarked) {
      formdata.append("isBookmark", 0);
      const result = await setEbookBookmark(formdata);
      // alert("보관취소");
      setIsBookmarked(false);
    } else {
      formdata.append("isBookmark", 1);
      const result = await setEbookBookmark(formdata);
      const returnInt = result.returnInt;
      if (returnInt == 0) {
        confirmLogin(navigate);
      } else {
        // alert("보관완료");
        setIsBookmarked(true);
      }
    }
  };
  const favorImgRef = useRef(null);
  const favorImg = favorImgRef.current;
  const addFavor = async () => {
    // console.log(favorImg.fill);
    if (favorImg) {
      if (isFavor) {
        formdata.append("isFavor", 0);
        const result = await setEbookFavor(formdata);
        setIsFavor(false);
      } else {
        formdata.append("isFavor", 1);
        const result = await setEbookFavor(formdata);
        const returnInt = result.returnInt;
        if (returnInt == 0) {
          confirmLogin(navigate);
        } else {
          setIsFavor(true);
        }
      }
    }
  };

  const cmtInfoRef = useRef(null);
  const openComplain = (e, cmtInfo) => {
    // console.log("user", user);
    // console.table(cmtInfo);
    cmtInfoRef.current = cmtInfo??'';
    user ? setIsOpen(true) : confirmLogin(navigate);
  };
  const [inputComplain, setInputComplain] = useState("");

  const handleCopyClipBoard = async (copyText) => {
    await navigator.clipboard.writeText(copyText);
  };

  const headerRef = useRef(null);
  const [bgHeight, setBgHeight] = useState("");
  useEffect(() => {
    if (headerRef) {
      if (mobileBg.current) {
        // console.log(headerRef.current.getBoundingClientRect().top);
        setBgHeight(
          parseInt(
            window.pageYOffset +
              headerRef.current.getBoundingClientRect().top +
              10
          ) + "px"
        );
        // mobileBg.current.style.height =
        //   window.pageYOffset + headerRef.current.getBoundingClientRect().top + "px";
      }
    }
  }, []);

  const onClickSubscribe = () => {
    if (user) {
      if (isSubscribe) {
        requestUnScribe(mSeq);
      } else {
        requestScribe(mSeq);
      }
    } else {
      confirmLogin(navigate);
    }
  };

  function UnderBtns() {
    return (
      <S.Under className="under">
        <div className="left">
          <div
            className="goBtn"
            onClick={() => {
              onWebLink(detailData?.bookUrl);
            }}
          >
            보기
          </div>
          <div
            className={`bookmark ${isBookMarked ? "yes" : "no"} `}
            onClick={() => addBookmark()}
          >
            {isBookMarked ? "보관중" : "보관함"}
          </div>
          <S.HoverBtn
            className="favor svg"
            onClick={() => addFavor()}
            ref={favorImgRef}
            offImg={isFavor ? Heart_on : Heart_off}
            // onImg={Heart_on}
            hoverImg={Heart_hover}
          ></S.HoverBtn>
          <S.HoverBtn
            className="linkIn svg"
            onClick={() => setIsPop(true)}
            offImg={Share_off}
            hoverImg={Share_on}
          ></S.HoverBtn>
        </div>
        {!tablet && <CntBox data={detailData} />}
      </S.Under>
    );
  }

  function CntBox({ data }) {
    return (
      <S.Right className="right">
        <div className="viewCnt">
          <img className="icon" src={Views}></img>
          {data?.viewCnt}
        </div>
        <div className="favorCnt">
          <img className="icon" src={Hearts}></img>
          {data?.favorCnt}
        </div>
        <div className="commentCnt">
          <img className="icon" src={Communication}></img>
          {data?.commentList.length}
        </div>
      </S.Right>
    );
  }


  

  return (
    <>
      <Layout topBtn={tablet ? "64px" : false}>
        {tablet && (
          <S.PosterImgMobile bgurl={coverUrl}>
            {" "}
            {/* <img src={CCL}></img> */}
          </S.PosterImgMobile>
        )}
        <Container>
          {isLoading && similarLoading ? <Loading /> : null}
          <S.Wrapper>
            <S.InfoSection>
              {!tablet && (
                <S.PosterImgWrap bgurl={coverUrl}>
                  {/* <img src={CCL}></img> */}
                </S.PosterImgWrap>
              )}
              <S.EbookInfo>
                <div className="upper">
                  <div className="regDate">
                    {detailData?.regDate.slice(0, 10)}
                    {tablet && <CntBox data={detailData} />}
                  </div>
                  <div className="flexBox">
                    <span className="ebookTitle">{bookTitle}</span>
                    <S.HoverBtn
                      className="complain"
                      onClick={openComplain}
                      offImg={Complain_off}
                      hoverImg={Complain_on}
                    ></S.HoverBtn>
                  </div>
                  <div className="nickname">
                    <S.NickPhoto
                      className="nickPhoto"
                      bgUrl={thumbUrl}
                      bgColor={thumbColor}
                    >
                      {!thumbUrl && nickName.charAt(0)}
                    </S.NickPhoto>
                    <Link to={`/yourLibrary/${mSeq}`}>{nickName}</Link>
                    {isSubscribe !== 3 && (
                      <span className="subscribe" onClick={onClickSubscribe}>
                        {isSubscribe === 1 ? `구독중` : `구독`}
                      </span>
                    )}
                  </div>
                  <div className="tags">
                    {detailData?.tagList.map((tag, i) => (
                      <Link
                        to={`/search?keyword=${tag}`}
                        state={{
                          hashTag: true,
                        }}
                        key={i}
                      >
                        {`${tag}`}
                      </Link>
                    ))}
                  </div>
                  {cclTf ? ( 
                    <>
                      <div className="ccl">
                        {/* 저작자표시 */}
                          {cclTf ? (
                          <S.Cclicon bgImg={ccby} title="CC BY: 저작자와 출처를 표시해야 합니다."/>
                        ):null}
                        {cclTf ? ( 
                          <>
                            {/* 비영리표시 */}
                            {!profitTf ? (
                              <S.Cclicon bgImg={ccnc} title="CC NC: 비영리 목적으로만 사용할 수 있습니다."/>
                            ):null}
                            {/* 변경금지표시 */}
                            {changeTf ? (
                              <S.Cclicon bgImg={ccsa} title="CC SA: 내 저작물을 이용해 새로운 저작물을 창작하는 경우, 동일한 라이선스를 붙여야 합니다."/>
                            ): (
                              <S.Cclicon bgImg={ccnd} title="CC ND: 변경하거나 다른 창작물에 이용하지 말아주세요."/>
                            )}
                          </>
                        ): null}
                      </div>
                      <div className="help">
                        {/* 영리가능, 변경가능 */}
                        {(profitTf && changeTf) ? (
                          <>
                          CC BY-SA : 출처를 표시해야 하고 무료로 사용했다면 무료로 공유허락을 해야 한다는 CCL 의미입니다.
                          </>
                        ):null}
                        {/* 영리불가능, 변경불가능 */}
                        {(!profitTf && !changeTf) ? (
                          <>
                          CC BY-NC-ND : 출처 표시와 비영리 조건이며 수정도 해서는 안 된다는 CCL 의미입니다.
                          </>
                        ):null}
                        {/* 영리가능, 변경불가능 */}
                        {(profitTf && !changeTf) ? (
                          <>
                          CC BY-ND : 출처를 표시해야 하고 변경 없이 그대로 사용해야 한다는 CCL 의미입니다.
                          </>
                        ):null}
                        {/* 영리불가능, 변경가능 */}
                        {(!profitTf && changeTf) ? (
                            <>
                          CC BY-NC-SA : 출처 표시와 비상업적 조건과 같은 공유허락을 해야 한다는 CCL 의미입니다.
                            </>
                        ):null}
                        
                        <a href="https://ccl.cckorea.org/about/" target="_blank"> [ 자세히 알아보기 ]</a>
                      </div>
                    </>
                  ):null}
                  
                </div>
                {!tablet && <UnderBtns />}
              </S.EbookInfo>
            </S.InfoSection>
            <TitleLine>소개</TitleLine>
            <Information data={bookDesc}></Information>
            <TitleLine>추천 목록</TitleLine>
            <Aside data={similarData?.rows} />
            <CommentsSection data={commentList} id={id} openComplain={openComplain}/>
          </S.Wrapper>
        </Container>
        {tablet && <UnderBtns />}

        {isPop ? (
          <S.PopupWrap bgDark={mobileLg}>
            <S.PopupShere scaleDown={mobileLg}>
              <div className="head">
                <h3>공유</h3>
                <div className="close" onClick={() => setIsPop(false)}></div>
              </div>
              <div className="links">
                <S.LinkIcon
                  className="Kakao"
                  bgImg={Kakao}
                  onClick={() => shareKakao(bookTitle)}
                />
                <S.LinkIcon
                  className="KakaoStory"
                  bgImg={KakaoStory}
                  onClick={() =>
                    shareKakakoStory(bookTitle, window?.location.href)
                  }
                  // onClick={() =>
                  //   shareKakakoStory(bookTitle, "https://www.kpub.co.kr/ebook/175")
                  // }
                />
                <S.LinkIcon className="Facebook" bgImg={Facebook} onClick={() =>
                  window?.open(
                    `http://www.facebook.com/sharer.php?u=${encodeURIComponent(
                      window?.location.href
                    )}`
                  )
                  // window?.open(
                  //   `http://www.facebook.com/sharer.php?u=https://www.kpub.co.kr/ebook/175`,"traget='_blank'","toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=800, height=600, top=0,left=0"
                  // )
                  
                }/>
                <S.LinkIcon className="Twitter" bgImg={Twitter} onClick={() =>
                  shareTwit(
                    bookTitle,
                    encodeURIComponent(window?.location.href)
                  )
                  // shareTwit(
                  //   bookTitle,
                  //   "https://www.kpub.co.kr/ebook/175"
                  // )
                }/>
                {/* <S.LinkIcon className="Instargram" bgImg={Instargram} /> */}
                {/* <S.LinkIcon className="MoreNavi" bgImg={MoreNavi} onClick={() =>
                  shareDotdotdot(
                    bookTitle,
                    encodeURIComponent(window?.location.href),
                    ()=>{}
                  )
                }/> */}
              </div>
              <div
                className="copyUrl"
                onClick={() => {
                  setIsPop(false);
                  setIsNotice(true);
                  setTimeout(() => {
                    setIsNotice(false);
                  }, [2000]);
                  handleCopyClipBoard(data?.row?.shortUrl);
                }}
              >
                <span>{data?.row?.shortUrl}</span>
                <div className="copyBtn">링크복사</div>
              </div>
            </S.PopupShere>
          </S.PopupWrap>
        ) : null}
        {isOpen ? (
          <Modal
            title="신고하기"
            width={mobileLg ? 310 : 437}
            id={id}
            itemType={"E"}
            input={inputComplain}
            commentInfo={cmtInfoRef.current}
          >
            <textarea
              placeholder="이 내용을 신고하는 이유를 적어주세요."
              value={inputComplain}
              onChange={(e) => setInputComplain(e.target.value)}
            ></textarea>
          </Modal>
        ) : null}
        {isNotice ? <Notice text="클립보드에 복사되었습니다." /> : null}
        {mobileComments ? <MobileComments lists={commentList} id={id} /> : null}
        <BlankBox height="40" />
      </Layout>
      {tablet && <BlankBox height="50" />}
      {responseMessage.length > 0 && (
        <S.MessageBase
          onClick={() => {
            setResponseMessage("");
            window.location.reload();
          }}
        >
          <S.MessageBox>{responseMessage}</S.MessageBox>
        </S.MessageBase>
      )}
      {responseMessageUs.length > 0 && (
        <S.MessageBase
          onClick={() => {
            setResponseMessageUs("");
            window.location.reload();
          }}
        >
          <S.MessageBox>{responseMessageUs}</S.MessageBox>
        </S.MessageBase>
      )}
    </>
  );
}
