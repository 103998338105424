import styled from "styled-components";
const Wrap = styled.div`
  width: 100%;
  
  .noneSearched {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 56vh;
    margin: 0 auto;
    color: ${(props) => props.theme.gray500};
    font-family: "Pretendard-Regular";
  }
`;

export default function EbookContainer({ children }) {
  return <Wrap>{children}</Wrap>;
}
