import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkLoginToken } from "./core/api";
import { COOKIE_NAME, getCookie, setLoginCookie } from "./core/cookie";

// const BASE_URL = "https://image.tmdb.org/t/p";

// export const makeImgPath = (id, format) => {
//   return `${BASE_URL}/${format ? format : "original"}/${id}`;
// };

export const useUser = () => {
  const accessToken = getCookie(COOKIE_NAME.ACCESS_TOKEN);
  const [user, setUser] = useState();
  useEffect(() => {
    setUser(accessToken ? true : false);
  }, [accessToken]);
  return { user, accessToken };
};

/*
export const useUser = () => {
  const { data: hasToken } = useQuery(["checkLoginToken"], checkLoginToken);
  const [user, setUser] = useState();
  const keepLogin = getCookie("KEEPLOGIN") == "true" ? true : false;

  useEffect(() => {
    if (hasToken?.returnInt == 1) {
      setUser(true);
      if (hasToken?.row.accessToken !== null) {
        const accessToken = hasToken?.row.accessToken;
        const refreshToken = hasToken?.row.refreshToken;
        setLoginCookie(keepLogin, accessToken, refreshToken);
      }
    } else setUser(false);
  }, [hasToken]);
  return { user };
};
*/

export const useScale = (initSize) => {
  const element = useRef();
  useEffect(() => {
    const diff = window.innerWidth / initSize;
    if (element.current) {
      element.current.style.transform = `scaleY(${diff.toFixed(2)})`;
    }
  }, []);

  return element;
};

export const getThumbnailColor = (mSeq) => {
  const thumbColors = [
    "#F9E79F",
    "#F9B59F",
    "#F8F99F",
    "#C6F99F",
    "#9FF9C4",
    "#9FF5F9",
    "#9FA2F9",
    "#EA9FF9",
  ];

  return thumbColors[mSeq % thumbColors.length];
};

export const removeDuplicates = (array, compareFunction) => {
  return array.reduce((unique, item) => {
    let found = false;

    for (const uniqueItem of unique) {
      if (compareFunction(uniqueItem, item)) {
        found = true;
        break;
      }
    }

    return found ? unique : [...unique, item];
  }, []);
};

export const appendUnique = (originalList, appendList, compareFunction) => {
  appendList.forEach((appendItem) => {
    let found = false;

    for (const originalItem of originalList) {
      if (compareFunction(originalItem, appendItem)) {
        found = true;
        break;
      }
    }

    if (!found) {
      originalList.push(appendItem);
    }
  });

  return originalList;
};

export const getRequestBody = (params) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(params)) {
    formData.append(key, value);
  }

  return formData;
};

export const confirmLogin = (navigate) => {
  // const navigate = useNavigate(); 사용할 컴포넌트에 선언해주기
  if (window.confirm("로그인이 필요한 기능입니다. 로그인을 하시겠습니까?")) {
    navigate("/login");
    window.location.reload();
  }
};

export const sortingByPram = (arr, param, boolean) => {
  let result = arr ? [...arr] : [];

  // console.log(boolean);
  result.sort((a, b) => {
    if (param === "viewCnt") return b.viewCnt - a.viewCnt;
    if (param === "favorCnt") return b.favorCnt - a.favorCnt;
    if (param === "contentName") {
      if (boolean) return a.bookTitle > b.bookTitle ? -1 : 0;
      else return a.bookTitle < b.bookTitle ? -1 : 0;
    }
    if (param === "publicTF") {
      if (boolean) return b.publicTF - a.publicTF;
      else return a.publicTF - b.publicTF;
    }
    if (param === "regDate") {
      if (boolean) return new Date(a.regDate) - new Date(b.regDate);
      else return new Date(b.regDate) - new Date(a.regDate);
    }
    return 0;
  });
  // console.log("param", param, "result", result);
  return result;
};
