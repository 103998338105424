import styled from "styled-components";

const Title = styled.div`
  margin-top: 40px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  h3 {
    padding-bottom: 8px;
    font-family: "Pretendard-bold";
    font-size: 22px;
    line-height: 26px;
    color: #242424;
  }
`;

export default function Information({ children }) {
  return (
    <Title>
      <h3>{children}</h3>
    </Title>
  );
}
