import styled from "styled-components";
import Layout from "../components/Layout";
import Container from "../components/Container";
import banner from "../images/pages/solution/banner.png";
import banner_m from "../images/pages/solution/banner_m.png";
import bg_1 from "../images/pages/solution/bg_1.png";
import img0_1 from "../images/pages/solution/img0_1.png";
import img1_1 from "../images/pages/solution/img1_1.png";
import img2_1 from "../images/pages/solution/img2_1.png";
import img3_1 from "../images/pages/solution/img3_1.png";
import email from "../images/pages/solution/email.svg";
import phone from "../images/pages/solution/phone.svg";
import solution_m from "../images/pages/solution/solution_m.png";

import { useMediaQuery } from "react-responsive";
import Hashtag from "../components/Hashtag";

const Img = styled.img`
  display: block;
  margin-top: ${(props) => (props.tablet ? "0" : "29px")};
  width: 100%;
`;
const Section = styled.section`
  width: 100%;
  height: ${(props) => (props.height ? props.height : "auto")};
  background: ${(props) => (props.bgImg ? `url(${props.bgImg}) center / 100% auto no-repeat` : "none")};

  padding-top: 120px;
  .flexBox {
    display: flex;
  }
  &.sec_0 {
    padding-left: 138px;
    .colorGradient {
      width: 340px;
      height: 176px;
      font-family: "Pretendard-Bold";
      font-size: 80px;
      line-height: 88px;
      background: linear-gradient(90deg, #ff8533 2.5%, #ffc814 97.79%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    img {
      position: relative;
      display: block;
      top: -10px;
      width: 242px;
      height: 192px;
    }
    p {
      font-family: "Pretendard-Medium";
      font-size: 22px;
      line-height: 34px;
      color: #303441;
    }
  }
  &.sec_1 {
    background: linear-gradient(180deg, rgba(63, 116, 243, 0.08) 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
    .num {
      color: #3f74f3;
    }
    img {
      width: 396px;
      height: 352px;
    }
  }
  &.sec_2 {
    background: linear-gradient(180deg, rgba(255, 125, 187, 0.06) 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
    .num {
      color: #ff3e9b;
    }
    img {
      width: 327px;
      height: 392px;
    }
  }
  &.sec_3 {
    background: linear-gradient(180deg, rgba(30, 204, 146, 0.04) 0%, rgba(255, 255, 255, 0) 100%), #ffffff;
    .num {
      color: #1ecc92;
    }
    img {
      width: 504px;
      height: 402px;
    }
  }
  &.sec_1,
  &.sec_2,
  &.sec_3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #dadfe7;
    .num {
      font-family: "Pretendard-SemiBold";
      font-size: 80px;
      line-height: 95px;
    }
    p {
      font-family: "Pretendard-Medium";
      font-size: 22px;
      line-height: 26px;
      color: #727585;
      margin: 10px 0 60px;
    }
    img {
      display: block;
    }
  }
  &.sec_4 {
    background: #f8fafb;
    border-top: 1px solid #dadfe7;
    padding: 120px 70px 0;
    h3 {
      font-family: "Pretendard-SemiBold";
      font-size: 32px;
      line-height: 38px;
      color: #303441;
    }
    p {
      font-family: "Pretendard-Medium";
      font-size: 16px;
      line-height: 19px;
      color: #727585;
      margin-top: 12px;
    }
    .flexBox {
      width: fit-content;
      margin: 20px auto 0;
      gap: 140px;
      > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 260px;
        height: 260px;
        background: #ffffff;
        box-shadow: 0px 6px 18px rgba(217, 230, 242, 0.5);
        border-radius: 14px;
        img {
          margin-top: 42px;
        }
        div {
          font-family: "Pretendard-Medium";
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #303441;
        }
        &.email {
          img {
            margin-bottom: 47px;
          }
        }
        &.phone {
          pointer-events: none;
          img {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
`;
const MobileWrap = styled.div`
  width: 100%;
  position: relative;
  .buttonWrap {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 4%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    aspect-ratio: 375/319;
    /* border: 1px solid red; */
    a {
      width: 92%;
      height: auto;
      aspect-ratio: 343/90;
      /* background-color: aqua;
      opacity: 0.3; */
      &:nth-last-child(1) {
        margin-bottom: 10%;
      }
    }
  }
`;

export default function Solution() {
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  return (
    <Layout>
      <Hashtag />
      {!tablet ? (
        <>
          <Container>
            <Img src={banner} alt="" />
            <Section height="504px" bgImg={bg_1} className="sec_0">
              <div className="flexBox">
                <div className="colorGradient">
                  KPUB
                  <br />
                  Premium
                </div>
                <img src={img0_1} alt="" />
              </div>
              <p>KPUB Premium으로 다양한 혜택을 만나보세요.</p>
            </Section>
            <Section height="788px" className="sec_1">
              <div className="num">1</div>
              <p>용량 제한 없이 콘텐츠 제작 및 업로드</p>
              <img src={img1_1} alt="" />
            </Section>
            <Section height="824px" className="sec_2">
              <div className="num">2</div>
              <p>로컬에서 사용 가능하도록 제공</p>
              <img src={img2_1} alt="" />
            </Section>
            <Section height="834px" className="sec_3">
              <div className="num">3</div>
              <p>직접 제작한 템플릿/컴포넌트/프로젝트 스토어에 업로드</p>
              <img src={img3_1} alt="" />
            </Section>
            <Section height="589px" className="sec_4">
              <h3>CONTACT US</h3>
              <p>KPUB Premium 상담</p>
              <div className="flexBox">
                <a className="email" href="mailto:help@tovsoft.com">
                  <img src={email} alt="" />
                  <div className="text">help@tovsoft.com</div>
                </a>
                <a className="phone" href="tel:02-6959-2716">
                  <img src={phone} alt="" />
                  <div className="text">
                    TEL : 02 – 6959 – 2716
                    <br />
                    FAX : 070 – 7176 – 1004
                  </div>
                </a>
              </div>
            </Section>
          </Container>
        </>
      ) : (
        <MobileWrap>
          <Img src={banner_m} alt="" tablet />
          <Img src={solution_m} alt="" tablet />
          <div className="buttonWrap">
            <a className="email" href="mailto:help@tovsoft.com"></a>
            <a className="phone" href="tel:02-6959-2716"></a>
          </div>
        </MobileWrap>
      )}
    </Layout>
  );
}
