import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { getEbookTagList, getPopularList, getRecentUploadList } from "../core/api";

const Wrapper = styled.div`
  position: sticky;
  left: 0;
  top: 56px;
  width: 100%;
  min-width: 1200px;
//  width: 100%;
  /* border-bottom: 0.5px solid #dbdbdb; */
  background-color: #fff;
  z-index: 2;
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    min-width: 100%;
    height: 48px;
    /* border-bottom: none; */
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    @media ${({ theme }) => theme.device.tablet} {
      justify-content: flex-start;
      overflow-x: scroll;

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
    }
  }
  ul > li {
    padding: 5px 18px;
    margin: 0 8px;
    color: #4e4b4b;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    border-radius: 32px;
    background-color: #f0f0f0;
    @media ${({ theme }) => theme.device.tablet} {
      flex: 0 0 auto;
      padding: 4px 14px;
    }
  }
`;
const Hashtag = () => {
  const { data, refetch } = useQuery(["tag"], getEbookTagList);
  // E-book List
  // const { data: recentUpLoad } = useQuery(["recentUpLoad"], getRecentUploadList);
  // const [recentUpLoads, setRecentUpLoads] = useState([]);
  // const { data: popular } = useQuery(["popular"], getPopularList);
  // const [popularContents, setPopularContents] = useState([]);

  // useEffect(() => {
  //   if (recentUpLoad?.rows) {
  //     setRecentUpLoads((old) => [...recentUpLoad?.rows]);
  //   }
  // }, [recentUpLoad]);
  // useEffect(() => {
  //   if (popular?.rows) {
  //     setPopularContents((old) => [...popular?.rows]);
  //   }
  // }, [popular]);


  return (
    <Wrapper>
      <ul>
        {/* <li>
          <Link
            to={`/moreEbook`}
            state={{
              title: "#최신",
              data: recentUpLoads,
            }}
          >
            #최신
          </Link>
        </li>
        <li>
          <Link
            to={`/moreEbook`}
            state={{
              title: "#인기",
              data: popularContents,
            }}
          >
            #인기
          </Link>
        </li> */}
        {data?.rows?.slice(0, 10).map((tag) => (
          <li key={tag.tagSeq}>
            <Link
              to={`/search?keyword=${tag.tagName}`}
              state={{
                hashTag: true,
              }}
            >
              {`${tag.tagName}`}
            </Link>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default Hashtag;
