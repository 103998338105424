import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getPopularList, getRecentUploadList } from "../core/api";
import recent from "../images/components/iconContainer/recent.png";
import popularImg from "../images/components/iconContainer/popular.png";
import catalog from "../images/components/iconContainer/catalog.png";
import photoBook from "../images/components/iconContainer/photoBook.png";
import fun from "../images/components/iconContainer/fun.png";
import trip from "../images/components/iconContainer/trip.png";

const IconList = styled.div`
  width: 100%;
  min-width: 1200px;
  padding: 0 16px;
  margin: 0 auto;
  ul {
    display: grid;
    grid-template-columns: repeat(6, 92.5px);
    justify-content: center;
    gap: 116.5px;
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        text-align: center;
        font-family: "Pretendard-SemiBold";
        font-size: 18px;
        line-height: 22px;
      }
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    min-width: unset;
    ul {
      grid-template-columns: repeat(3, 72px);
      gap: 16px 0;
      justify-content: space-between;
      li {
        .icon {
          width: 72px;
          height: 72px;
        }
        span {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
`;

const IconImg = styled.div`
  width: 92.5px;
  height: 92.5px;
  background-color: #f2f2f2;
  border-radius: 20px;
  margin-bottom: 7.5px;
  background: #f2f2f2 url(${(props) => props.img}) center/ 100% auto no-repeat;
  @media ${({ theme }) => theme.device.tablet} {
    width: 72px;
    height: 72px;
  }
`;

export default function IconContainer() {
  const { data: recentUpLoad } = useQuery(["recentUpLoad"], getRecentUploadList);
  const [recentUpLoads, setRecentUpLoads] = useState([]);
  const { data: popular } = useQuery(["popular"], getPopularList);
  const [popularContents, setPopularContents] = useState([]);

  useEffect(() => {
    if (recentUpLoad?.rows) {
      setRecentUpLoads((old) => [...recentUpLoad?.rows]);
    }
  }, [recentUpLoad]);
  useEffect(() => {
    if (popular?.rows) {
      setPopularContents((old) => [...popular?.rows]);
    }
  }, [popular]);

  return (
    <IconList>
      <ul>
        <Link
          to={`/contents`}
          state={{
            title: "최신순",
            data: recentUpLoads,
            iconList: true,
            options: { ordering: 0 },
          }}
        >
          <li>
            <IconImg img={recent}></IconImg>
            <span>최신순</span>
          </li>
        </Link>

        <Link
          to={`/contents`}
          state={{
            title: "인기순",
            data: popularContents,
            iconList: true,
            options: { ordering: 1 },
          }}
        >
          <li>
            <IconImg img={popularImg}></IconImg>
            <span>인기순</span>
          </li>
        </Link>

        <Link
          to={`/search?keyword=카탈로그`}
          state={{
            iconList: true,
          }}
        >
          <li>
            <IconImg img={catalog}></IconImg>
            <span>카탈로그</span>
          </li>
        </Link>
        <Link
          to={`/search?keyword=사진첩`}
          state={{
            iconList: true,
          }}
        >
          <li>
            <IconImg img={photoBook}></IconImg>
            <span>사진첩</span>
          </li>
        </Link>
        <Link
          to={`/search?keyword=재미`}
          state={{
            iconList: true,
          }}
        >
          <li>
            <IconImg img={fun}></IconImg>
            <span>재미</span>
          </li>
        </Link>
        <Link
          to={`/search?keyword=여행`}
          state={{
            iconList: true,
          }}
        >
          <li>
            <IconImg img={trip}></IconImg>
            <span>여행</span>
          </li>
        </Link>
      </ul>
    </IconList>
  );
}
