import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import TopBtn from "./TopBtn";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { modalState } from "../atoms";
import { scrollBtnState } from "../atoms";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.bgColor};
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
  }
`;
const Main = styled.div`
  /* width: 1200px;
  padding: 0 16px;
  margin: 0 auto; */
  min-height: 83.9vh;
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    min-height: fit-content;
  }
`;

export default function Layout({ children, topBtn }) {
  const location = useLocation();
  const [modal, setModal] = useRecoilState(modalState);
  const [scollBtn, setScollBtn] = useRecoilState(scrollBtnState);
  // const [BtnStatus, setBtnStatus] = useState(false); // 버튼 상태
  const body = document.querySelector("body");

  useEffect(() => {
    window.scrollTo(0, 0);
    setScollBtn(false);
    setModal(false);
    body.style.overflow = "auto";
  }, [location]);

  const [ScrollY, setScrollY] = useState(0); // 스크롤값을 저장하기 위한 상태
  const handleFollow = () => {
    setScrollY(window.pageYOffset); // window 스크롤 값을 ScrollY에 저장
    if (ScrollY > 100) {
      setScollBtn(true);
    } else {
      setScollBtn(false);
    }
  };

  useEffect(() => {
    const watch = () => {
      window.addEventListener("scroll", handleFollow);
    };
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener("scroll", handleFollow); // addEventListener 함수를 삭제
    };
  });
  
  const user = navigator.userAgent;
  const windowInnerWidth = window.innerWidth;

  return (
    <Wrapper className="layout-Wrapper">
      <Header />
      <Main>{children}</Main>
      {/* { ((user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1) && (windowInnerWidth < 480)) ? null : <Footer/> } */}
      <Footer/>
      {scollBtn ? <TopBtn bottom={topBtn} /> : null}
    </Wrapper>
  );
}
