import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import ThumbnailRecentUpload, { ThumbnailDummy } from "./ThumbnailRecentUpload";

const Wrap = styled.div`
  position: relative;
  display: grid;
  
  grid-template-columns: repeat(${(props) => (props.myRoom ? 4 : 6)}, 264px);
  grid-column-gap: 36.5px;
  /* grid-template-columns: repeat(auto-fill, minmax(158px, 1fr)); */
  // grid-template-columns: repeat(6, minmax(0px, 1fr));

  justify-items: space-between;


  height: ${(props) => (props.row ? "fit-content" : "fit-content")};

  overflow: hidden;
  width: ${(props) => (props.myRoom ? "892px" : "1768px")};
  grid-row-gap: ${(props) => (props.myRoom ? "25px" : "20px")};
  margin: 0 auto;
  margin-bottom: ${(props) => (props.row ? "0" : "40px")};
  > div {
    width: 100%;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(2, 48%);
    grid-column-gap: 10px;
    height: fit-content;
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;

export default function ThumbGridRecentUpload({ data, row, lastElementRef, myRoom, title }) {
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  return (
    <Wrap row={row} myRoom={myRoom}>      
      {tablet && row
       ? data.map((content, i) => (
                <div key={i} ref={data.length - 1 === i ? lastElementRef : undefined}>
              <ThumbnailRecentUpload
                bgurl={content.coverUrl}
                title={content.bookTitle}
                publisher={content.nickName}
                view={content.viewCnt}
                id={content.eSeq}
                favor={content.favorCnt}
                bookUrl={content.bookUrl}
              ></ThumbnailRecentUpload>
            </div>
          ))
        : data.map((content, i) => (
            <div key={i} ref={data.length - 1 === i ? lastElementRef : undefined}>
              <ThumbnailRecentUpload
                bgurl={content.coverUrl}
                title={content.bookTitle}
                publisher={content.nickName}
                view={content.viewCnt}
                id={content.eSeq}
                favor={content.favorCnt}
                bookUrl={content.bookUrl}
              ></ThumbnailRecentUpload>
            </div>
          ))}
    </Wrap>
  );
}
