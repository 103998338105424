import styled from "styled-components";
import arrow from "../images/pages/faq/arrow_down.svg";
import { ReactComponent as Serching_icon } from "../images/pages/faq/serching_icon.svg";
import Q_icon_off from "../images/pages/faq/Q_icon_off.svg";
import Q_icon_on from "../images/pages/faq/Q_icon_on.svg";
import A_icon_on from "../images/pages/faq/A_icon_on.svg";
import Down_icon from "../images/pages/faq/akar-icons_chevron-down.svg";
import Up_icon from "../images/pages/faq/akar-icons_chevron-up.svg";
import { useEffect, useRef, useState } from "react";
import PageTitle from "../components/PageTitle";
import Layout from "../components/Layout";
import Container from "../components/Container";
import { getFaqCategoryList, getFaqList } from "../core/api";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import Loading from "../components/Loading";
import { useMediaQuery } from "react-responsive";

const Inner = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;
const TitleLine = styled.div`
  margin-bottom: 27px;

  > div {
    display: flex;
    justify-content: space-between;
    /* IE */
    select::-ms-expand {
      display: none;
    }
    select {
      -o-appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      display: inline-block;
      width: 208px;
      height: 36px;
      font-size: 16px;
      color: ${(props) => props.theme.gray500};
      padding: 0 20px;

      outline: none;
      border: 1px solid ${(props) => props.theme.gray300};
      border-radius: 4px;
      background: url(${arrow}) center right 15px no-repeat;
    }

    @media ${({ theme }) => theme.device.mobile} {
      flex-direction: column;
      > * {
        width: 160px;
      }
    }
  }
`;
const SerchingForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 320px;
  height: 36px;
  background: #f5f5f5;
  border-radius: 36px;
  padding: 0 16px;
  input {
    display: block;
    height: 100%;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${(props) => props.theme.gray500};
    outline: none;
    border: none;
    background: #f5f5f5;
  }
  button {
    display: block;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    &:active {
      transform: scale(0.95);
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    min-width: 196px;
  }
`;
const SelectBox = styled.div`
  position: relative;
  display: inline-block;
  max-width: 208px;
  width: 40%;
  height: 36px;
  font-size: 16px;
  color: ${(props) => props.theme.gray500};
  padding: 0 20px;

  border: 1px solid ${(props) => props.theme.gray300};
  border-radius: 4px;
  background: url(${arrow}) center right 15px no-repeat;
  .selected {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: none;
  }
  ul {
    position: absolute;
    width: calc(100% + 2px);
    left: 0;
    margin: 4px 0 0 -1px;
    background: #ffffff;
    /* gray/gray-300 */

    border: 1px solid ${(props) => props.theme.gray300};
    border-radius: 4px;
    cursor: pointer;
    li {
      display: flex;
      align-items: center;
      height: 36px;
      font-size: 16px;
      padding: 0 20px;
      &:not(:nth-child(1)) {
        border-top: 1px solid ${(props) => props.theme.gray200};
      }

      &:hover {
        color: ${(props) => props.theme.gray800};
        background-color: ${(props) => props.theme.gray100};
      }
    }
  }
`;
const FaqContainer = styled.ul`
  width: 100%;
  margin-bottom: 40px;
  @media ${({ theme }) => theme.device.tablet} {
    min-height: 340px;
  }
  li {
    width: 100%;
    &:nth-child(1) {
      border-top: 1px solid ${(props) => props.theme.gray300};
    }
    border-bottom: 1px solid ${(props) => props.theme.gray300};
    background: url(${Down_icon}) top 22px right 40px no-repeat;
    @media ${({ theme }) => theme.device.tablet} {
      background: url(${Down_icon}) top 22px right 20px no-repeat;
    }
    cursor: pointer;
    > * {
      padding: 0 90px 0 40px;
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      span.icon {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        margin-right: 16px;
        width: 28px;
        height: 28px;
      }
      span.text {
        height: fit-content;
        text-align: justify;
        /* word-break: keep-all; */
      }
      @media ${({ theme }) => theme.device.tablet} {
        padding: 0 67px 0 20px;
      }
    }

    .dt {
      height: 68px;
      color: ${(props) => props.theme.gray1000};
      pointer-events: none;

      span.icon {
        width: 29px;
        height: 29px;
        background: url(${Q_icon_off}) center no-repeat;
        pointer-events: none;
      }
      span.text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .dd {
      pointer-events: none;
      padding-bottom: 25px;
      display: none;
      min-height: 50px;
      align-items: center;
      span.icon {
        width: 29px;
        height: 29px;
        background: url(${A_icon_on}) center no-repeat;
      }
      span.text {
        margin-top: 2px;
        white-space: pre-wrap;
      }
    }
    &.open {
      border-top: 1px solid ${(props) => props.theme.gray600};
      border-bottom: 1px solid ${(props) => props.theme.gray600};
      background: url(${Up_icon}) top 22px right 40px no-repeat;
      @media ${({ theme }) => theme.device.tablet} {
        background: url(${Up_icon}) top 22px right 20px no-repeat;
      }
      & > * {
        align-items: flex-start;
      }
      .dt {
        min-height: 80px;
        height: fit-content;
        padding-bottom: 20px;
        span.icon {
          background: url(${Q_icon_on}) center auto no-repeat;
          margin-top: 20px;
        }
        span.text {
          overflow: visible;
          white-space: normal;
          margin-top: 22px;
        }
      }
      .dd {
        display: flex;
      }
    }
    &:not(:first-child).open {
      span.icon {
        margin-top: 19px;
      }
      span.text {
        margin-top: 21px;
      }
    }
  }
  .noneSearched {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 610px;
    margin: 0 auto;
    background: url(./images/icon_exclamation.svg) center 220px/ 64px 64px no-repeat;
    color: ${(props) => props.theme.gray500};
    font-family: "Pretendard-SemiBold";
    @media ${({ theme }) => theme.device.tablet} {
      height: 340px;
      background: url(./images/icon_exclamation.svg) center 60px/ 64px 64px no-repeat;
    }
  }
`;

export default function FAQ() {
  const [faqList, setFaqList] = useState([]);
  const [faqListAll, setFaqListAll] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("전체");
  const [selectedFcaSeq, setSelectedFcaSeq] = useState(-1);
  const formdata = new FormData();
  const { data, isLoading, refetch } = useQuery(["faqData"], () => getFaqList(formdata));
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  const makeOptionList = async () => {
    const result = await getFaqCategoryList();
    setOptionList(result.rows);
  };

  const makeFaqList = async () => {
    setFaqList(data?.rows);
    setFaqListAll(data?.rows);
  };
  useEffect(() => {
    makeOptionList();
    if (data) {
      makeFaqList();
    }
  }, [data, faqList]);
  const toggleOpen = (e) => {
    const _this = e.target;
    const allFaq = Array.from(e.target.parentElement.children);
    if (_this.classList.contains("open")) {
      _this.classList.remove("open");
    } else {
      for (const faq of allFaq) {
        faq.classList.remove("open");
      }
      _this.classList.add("open");
    }
  };
  const [active, setActive] = useState(false);
  const toggleSelectBox = () => {
    setActive(!active);
  };

  const selectOption = (e) => {
    setSelectedValue(e.target.innerText);
    setSelectedFcaSeq(e.target.value);
    setInputValue("");
    setActive(!active);
  };

  const changeFaqList = (selectedFcaSeq, inputValue) => {
    if (selectedFcaSeq !== -1) formdata.append("fcaSeq", selectedFcaSeq);
    if (inputValue !== null) formdata.append("searchText", inputValue);
    refetch();
  };

  //카테고리 선택 후 필터링, open닫기
  useEffect(() => {
    if (selectedFcaSeq === "-1") {
      setFaqList(faqListAll);
    } else {
      setFaqList(changeFaqList(selectedFcaSeq, null));
    }

    const openLi = document.querySelector(".open");
    if (openLi) {
      openLi.classList.remove("open");
    }
  }, [selectedValue]);

  //select 외부 눌렀을때 닫기
  const closeSelect = (e) => {
    const targetElement = e.target;
    const isSelect =
      targetElement.classList.contains("select") || targetElement.closest(".select");
    if (isSelect) {
      return;
    }

    setActive(false);
  };
  useEffect(() => {
    const watch = () => {
      document.addEventListener("click", closeSelect);
    };
    watch(); // addEventListener 함수를 실행
    return () => {
      window.removeEventListener("click", closeSelect); // addEventListener 함수를 삭제
    };
  }, [active]);

  const [inputValue, setInputValue] = useState("");
  const onChange = (e) => {
    setInputValue(e.target.value);
  };
  const onSearch = (e) => {
    e.preventDefault();
    // console.log(e.target.value);
    if (inputValue) {
      setFaqList(changeFaqList(selectedFcaSeq, inputValue));
    }
  };

  return (
    <Layout>
      <PageTitle title="FAQ" kind="faq">
        {tablet && (
          <SerchingBox onChange={onChange} onSearch={onSearch} inputValue={inputValue} />
        )}
      </PageTitle>
      <Container>
        <Inner>
          <TitleLine>
            <div>
              <SelectBox
                onClick={toggleSelectBox}
                className={active ? `${active} select` : "select"}
              >
                <div className="selected">
                  <div className="selected-value">{selectedValue}</div>
                </div>
                {active ? (
                  <ul>
                    <li onClick={selectOption} value="-1" className="option">
                      전체
                    </li>
                    {optionList.map((list) => (
                      <li
                        key={list.fcaSeq}
                        onClick={selectOption}
                        value={list.fcaSeq}
                        className="option"
                      >
                        {list.categoryName}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </SelectBox>
              {!tablet && (
                <SerchingBox
                  onChange={onChange}
                  onSearch={onSearch}
                  inputValue={inputValue}
                />
              )}
            </div>
          </TitleLine>
          <FaqContainer>
            {isLoading ? <Loading /> : null}
            {faqList?.length > 0 ? (
              faqList?.map(({ id, title, content, categoryName, faqImage }, i) => (
                <li key={i} onClick={toggleOpen}>
                  <div className="dt">
                    <span className="icon"></span>
                    <span className="text">
                      {i + 1}. {categoryName ? `${categoryName} | ` : null}
                      {title}
                    </span>
                  </div>
                  <div className="dd">
                    <span className="icon"></span>
                    <span className="text">
                      {content}
                      {faqImage ? (
                        <img
                          src={faqImage}
                          style={{ display: `block`, maxWidth: `100%` }}
                        ></img>
                      ) : null}
                    </span>
                  </div>
                </li>
              ))
            ) : !isLoading ? (
              <div className="noneSearched">검색 결과가 없습니다.</div>
            ) : null}
          </FaqContainer>
        </Inner>
      </Container>
    </Layout>
  );
}
const SerchingBox = ({ onChange, onSearch, inputValue }) => {
  return (
    <SerchingForm className="serchingBox" onSubmit={onSearch}>
      <input onChange={onChange} value={inputValue} type="text" placeholder="검색" />
      <button type="submit">
        <Serching_icon width={32} height={32} />
      </button>
    </SerchingForm>
  );
};
