import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { setLoginCookie } from "../core/cookie";
import { getTempSocialKeepLogin, removeTempSocialKeepLogin } from "../core/localStorage";

export default function SSOResult() {
    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        const onFailLogin = (ssoSite, ssoResult) => {
            let msg = '';
            if(ssoSite === 'googleResult') msg = '구글';
            else if(ssoSite === 'kakaoResult') msg = '카카오';
            else if(ssoSite === 'naverResult') msg = '네이버';
            msg += ' 소셜로그인에 실패하였습니다.';
            if(ssoResult === -1) msg += '<br>K-PUB에서 사용정지된 계정입니다.';
            else if(ssoResult === -2) msg += '<br>K-PUB에서 삭제처리된 계정입니다.';
            alert(msg);
    
            navigate('/login');
        };
    
        const onSuccessLogin = (accessToken, refreshToken) => {
            const keepLogin = getTempSocialKeepLogin();
            // 토큰 저장
            setLoginCookie(keepLogin, accessToken, refreshToken);
            removeTempSocialKeepLogin();
            
            // 메인 이동
            navigate('/');
            window.location.reload();
        };

        const searchParams = new URLSearchParams(location.search);
        const ssoSite = searchParams.get("ssoSite");
        let ssoResult = searchParams.get("ssoResult");
        const accessToken = searchParams.get("accessToken");
        const refreshToken = searchParams.get("refreshToken");

        if (ssoResult) {
            ssoResult = Number(ssoResult);
            if(ssoResult < 1) {
                onFailLogin(ssoSite, ssoResult);
            } else {
                onSuccessLogin(accessToken, refreshToken);
                navigate('/');
            }
        } else {
            navigate('/');
        }
    }, [location.search, navigate]);

  return (
    <Layout>
    </Layout>
  );
}
