import { useRef } from "react";
import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { bannerImg } from "../meta/imagePath";

const Wraper = styled.section`
  width: ${({ location }) => {
    switch (location) {
      case 'searchResult':
        return '1800px'
      case 'template':
        return '1800px';
      case 'jikjitemplate':
        return '1800px'
      default:
        return '1200px';
    }
  }};
  margin: 60px auto 24px;
  &.myLibrary {
    margin: 60px auto 32px;
    .left {
      flex-direction: row;
      align-items: center;
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    align-items: center;
    /* min-height: 82px; */
    padding-top: 0px;
    width: 100%;
    height: auto;
    margin: 60px auto 24px;
    &.myLibrary {
      margin: 32px auto 24px;
      .left {
        flex-direction: row;
        align-items: center;
      }
    }
  }
`;

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  padding: 0 16px;
  margin: 0 auto;
  .left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    h2 {
      font-size: 32px;
      line-height: 38px;
      color: #303441;
      margin-bottom: 2px;
    }
    h3 {
      font-family: "Pretendard-Medium";
      font-size: 18px;
      line-height: 22px;
      color: #727585;
    }
    .bedge {
      width: 48px;
      height: 48px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 22px;
      line-height: 26px;
      color: #262626;
    }
  }
  /* max-width: 1920px; */
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    flex-direction: ${(props) => (props.column ? `column` : `row`)};
    align-items: flex-start;
    height: 100%;
    /* padding-top: ${(props) => (props.column ? `29px` : 0)}; */

    /* padding-top: 29px; */
    .left {
      width: 100%;
      height: 100%;
      h2 {
        font-size: 28px;
        line-height: 34px;
      }
      .bedge {
        width: 40px;
        height: 40px;
        font-size: 20px;
        line-height: 24px;
      }
    }
    .right {
      width: ${(props) => (props.column ? `100%` : 0)};
      height: ${(props) => (props.column ? `100%` : 0)};
      display: flex;
      justify-content: flex-end;
    }
  }
`;


export default function PageTitle({
  title,
  kind,
  thumbUrl,
  thumbColor,
  children,
  description,
  location = '',
}) {
  const [index, setIndex] = useState(0);
  const bedgeRef = useRef(null);
  const images = bannerImg[kind];
  const maxIndex = images.length;

  useEffect(() => {
    if (bedgeRef.current) {
      bedgeRef.current.style.backgroundColor = thumbColor;
    }
  }, [thumbColor]);

  useEffect(() => {
    const next = setInterval(() => {
      setIndex((prev) => (prev === maxIndex - 1 ? 0 : prev + 1));
    }, 5000);
    return () => clearInterval(next);
  }, [maxIndex]);

  return (
    <Wraper className={kind ? kind : ''} location={location}>
      <Inner
        className="inner"
        column={children !== undefined && title !== "FAQ" ? true : false}
      >
        <div className="left">
          {thumbUrl !== undefined &&
            (thumbUrl.length > 0 ? (
              <img className="bedge" src={thumbUrl} alt={title} />
            ) : (
              <span className="bedge" ref={bedgeRef}>
                {title.charAt(0)}
              </span>
            ))}
          <h2 className="pretendardSB">{title}</h2>
{/*
          {<h3>{description ? description : ""}</h3>}
*/}
        </div>
        {children && <div className="right">{children}</div>}
      </Inner>
    </Wraper>
  );
}
