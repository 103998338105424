import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

const Box = styled(motion.div)`
  display: fixed;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 260px;
  height: 56px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  font-size: 18px;
  color: #fff;
`;

export default function Notice({ children, text }) {
  return (
    <AnimatePresence>
      <Box
        initial={{ bottom: "-60px" }}
        animate={{ bottom: "76px" }}
        exit={{ bottom: "-60px" }}
        transition={{ duration: 0.3, type: "tween" }}
      >
        {children}
        <h3>{text}</h3>
      </Box>
    </AnimatePresence>
  );
}
