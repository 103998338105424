import styled from "styled-components";

import naverBtn from "../images/pages/Login/NaverBtn.svg";
import katalkBtn from "../images/pages/Login/KaTalkBtn.svg";
import googleBtn from "../images/pages/Login/GoogleBtn.svg";
import closeBtn from "../images/pages/Login/x_icon.svg";
import closeBtnHover from "../images/pages/Login/x_hover.svg";
import checkOn from "../images/pages/Login/checkBox_on.svg";
import checkOff from "../images/pages/Login/checkBox_off.svg";

const S = {};

S.Wrapper = styled.div`
  width: 100%;
  min-width: 1200px;
  min-height: 83.9vh;
  background-color: ${(props) => props.theme.gray100};
  border-bottom: 1px solid #cccccc;
  padding-top: 56px;
  @media ${({ theme }) => theme.device.tablet} {
    min-width: 100%;
    min-height: fit-content;
    padding: 0;
    background-color: ${(props) => props.theme.bgColor};
    border-bottom: none;
  }
`;
S.Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 556px;
  min-height: 653px;
  margin: 0 auto 122px;
  background: ${(props) => props.theme.bgColor};
  border: 1.5px solid ${(props) => props.theme.gray300};
  word-break: keep-all;
  h2 {
    font-family: "Pretendard-Medium";
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    color: ${(props) => props.theme.gray1000};
    margin: 30px 0 19px 0;
  }
  > div {
    margin: 40px 0 20px 0;

    span {
      cursor: pointer;
    }
    span:nth-child(1),
    span:nth-child(2) {
      color: ${(props) => props.theme.gray500};
    }
    span:nth-child(3) {
      color: ${(props) => props.theme.gray1000};
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.gray600};
    &.gray-500 {
      color: ${(props) => props.theme.gray500};
      em {
        color: ${(props) => props.theme.point01};
      }
    }
  }
  .btnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    span {
      width: 48px;
      height: 48px;
    }
    .naverBtn {
      background: url(${naverBtn}) center no-repeat;
    }
    .katalkBtn {
      margin: 0 24px;
      background: url(${katalkBtn}) center no-repeat;
    }
    .googleBtn {
      background: url(${googleBtn}) center no-repeat;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    border: none;
    margin: 0 auto 65px;
  }
`;
S.InnerFind = styled(S.Inner)`
  position: relative;
  padding-bottom: 63px;
  > div {
    margin-top: 30px;
    width: 80%;
    @media ${({ theme }) => theme.device.mobile} {
      width: calc(100% - 30px);
    }
    span {
      display: block;
      margin-top: 8px;
      cursor: default;
    }
    span.email {
      color: ${(props) => props.theme.gray600};
    }
  }
  div.accountTxt {
    width: fit-content;
    max-width: calc(100% - 30px);
    margin-bottom: 90px;
    > div:first-child {
      font-size: 16px;
      color: #000;
      margin-bottom: 8px;
    }
    div.flexRow {
      display: flex;
      gap: 10px;
      font-size: 18px;
      .flexCol {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
  div.flexBtn {
    display: flex;
    justify-content: space-between;
    margin: 0;
    button {
      width: 48%;
      margin-top: 30px;
      height: 60px;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;

      color: ${(props) => props.theme.bgColor};
      border: none;
      border-radius: 6px;
      cursor: pointer;
      &:nth-child(1) {
        background-color: ${(props) => props.theme.point01};
        &:hover {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
            ${(props) => props.theme.point01};
        }
      }
      &:nth-child(2) {
        color: ${(props) => props.theme.point01};
        background-color: ${(props) => props.theme.bgColor};
        border: 1px solid ${(props) => props.theme.point01};
        &:hover {
          background: ${(props) => props.theme.red100};
        }
      }
    }
  }
`;
S.Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  > label {
    padding: 0 8px;
    color: ${(props) => props.theme.gray800};
  }
  > input {
    width: 100%;
    height: 40px;
    font-size: 16px;
    padding: 0 8px;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.gray400};
    outline: none;
    &::placeholder {
      color: ${(props) => props.theme.gray400};
      @media ${({ theme }) => theme.device.tablet} {
        font-size: 13px;
      }
    }
  }
  > input:nth-of-type(2) {
    /* margin-top: 16px; */
  }
  > p {
    font-size: 12px;
    color: #ff0000;
    height: 24px;
    padding-left: 8px;
  }
  > p:nth-of-type(2) {
    margin-bottom: 8px;
  }
  .checkBox {
    position: relative;
    font-size: 14px;
    line-height: 16px;
    input {
      visibility: hidden;
      &:checked {
        & + label {
          color: ${(props) => props.theme.gray1000};
        }
        & + label::before {
          background: url(${checkOn}) center no-repeat;
        }
      }
    }
    label {
      position: relative;
      color: ${(props) => props.theme.gray400};
      margin-right: 24px;
      cursor: pointer;
      &::before {
        position: absolute;
        top: 0px;
        left: -20px;
        content: "";
        width: 16px;
        height: 16px;
        background: url(${checkOff}) center no-repeat;
      }
    }
  }
  button {
    margin-top: 30px;
    height: 60px;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;

    color: ${(props) => props.theme.bgColor};
    border: none;
    border-radius: 6px;
    background-color: ${(props) => props.theme.point01};
    cursor: pointer;
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        ${(props) => props.theme.point01};
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    width: calc(100% - 30px);
  }
`;
S.CheckBoxLabel = styled.label``;
S.FormFindId = styled(S.Form)`
  position: relative;
  > input:nth-of-type(2) {
    margin-top: 0px;
  }
  label {
    color: ${(props) => props.theme.gray800};
  }
  label:nth-of-type(2) {
    margin-top: 3px;
  }
  input:placeholder {
    color: ${(props) => props.theme.gray400};
  }
  div.findPwEmail {
    font-size: 26px;
    padding: 20px 0 180px 0px;
  }
  div.absolBox {
    position: absolute;
    top: ${(props) => (props.mode === "findEmail" ? "172px" : "252px")};
    right: 8px;
    display: flex;
    align-items: center;
    .timer {
      color: ${(props) => props.theme.gray1000};
      font-size: 12px;
    }
  }
  button.phoneAuth {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: ${(props) => (props.mode === "findEmail" ? "100px" : "188px")};
    right: 8px;
    width: fit-content;
    height: auto;
    padding: 6.5px 15px;
    margin: auto 0px;
    margin-left: 4px;
    border: 1px solid ${(props) => props.theme.point01};
    background-color: #fff;
    border-radius: 4px;
    color: ${(props) => props.theme.point01};
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    @media ${({ theme }) => theme.device.mobilelg} {
      height: 32px;
      font-size: 14px;
    }
    @media ${({ theme }) => theme.device.mobile} {
      height: 28px;
      font-size: 12px;
      margin-left: 8px;
    }
    &.confirm {
      position: static;
    }
  }
`;
S.CloseBtn = styled.span`
  position: absolute;
  display: block;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background-image: url(${closeBtn});
  cursor: pointer;
  :hover {
    background: url(${closeBtnHover}) center/100% no-repeat;
  }
`;
S.FormSignUp = styled(S.FormFindId)`
  p.gray {
    color: ${(props) => props.theme.gray400};
  }

  button.checkDuplicateId.phoneAuth {
    top: 15px;
  }
  button.phoneAuth {
    top: 410px;
  }
  div.absolBox {
    top: 474px;
  }
  .userType {
    /* margin-top: 24px; */
    margin-bottom: 6px;
  }
  .userTypeList {
  }
  div.terms {
    font-size: 16px;
    line-height: 24px;
  }
  .termsBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    font-family: "pretendard-SemiBold";
    font-size: 14px;
    line-height: 17px;
    padding: 6.5px 15px;
    margin-top: 6px;
    border: 1px solid ${(props) => props.theme.gray600};
    border-radius: 4px;
    cursor: pointer;
  }
  .buttonContainer {
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
      &.cancelBtn {
        background-color: ${(props) => props.theme.gray300};
        color: ${(props) => props.theme.gray600};
      }
    }
  }
`;

export default S;
