import styled from "styled-components";

const BlankBox = styled.div`
  width: 100%;
`;

export default function BlankBoxs({ height }) {
  return (
    <BlankBox
      style={{
        height: `${height}px`,
      }}
    ></BlankBox>
  );
}
