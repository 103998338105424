import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import App from "./App";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.REACT_APP_GA_KEY) {
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

root.render(
  // <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  // </React.StrictMode>
);
