import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Container from "../components/Container";
import {
  getMyLibraryList,
  getMemberInfo,
  updateMemberPhoto,
  getMySubscribeList,
  getMberEbookStat,
} from "../core/api";
import Loading from "../components/Loading";
import { getThumbnailColor, sortingByPram, useUser } from "../util";
import ThumbGrid from "../components/ThumbGrid";
import ContentsManager from "../components/ContentsManager.jsx";
import Statistics from "../components/Statistics.jsx";
import Subfilter from "../components/Subfilter";
import camera_black from "../images/pages/MyLibrary/camera_black.svg";
import { useNavigate, useParams } from "react-router-dom";
import UserList from "../components/UserList";
import { useMediaQuery } from "react-responsive";
import { selectedSubscribedUserState } from "../atoms";
import { useRecoilState } from "recoil";
import { ReactComponent as GridBtn } from "../images/pages/MyLibrary/showGridBtn.svg";
import { ReactComponent as ListBtn } from "../images/pages/MyLibrary/showListBtn.svg";

const SideTab = styled.aside`
  position: relative;
  //왼쪽padding 늘려서 없애기
  left: -16px;
  min-width: 236px;
  height: auto;
  min-height: 83.9vh;
  background: #f7f9fa;
  .myInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 40px 0 40px;
    .profile {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background-color: ${(props) => (props.bg ? "none" : props.bgColor)};
      background-image: url(${(props) => props.bg});
      background-position: center;
      background-size: cover;
      border: none;
      margin: 0 auto 10px;
      font-family: "Pretendard-Light";
      font-size: 36px;
      color: ${(props) => props.theme.gray800};
      input {
        position: absolute;
        width: 0; //숨김처리
        display: none;
      }
      .profileBtn {
        position: absolute;
        bottom: 0px;
        right: -10px;
        width: 28px;
        height: 28px;
        background: url(${camera_black});
        cursor: pointer;
      }
    }
    .flex-box {
      display: flex;
      padding: 0 30px;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      .nickName {
        font-family: "Pretendard-SemiBold";
        font-size: 20px;
        line-height: 24px;
        color: #0f0f10;
      }
      .creater {
        width: fit-content;
        font-family: "Pretendard-Medium";
        font-size: 11px;
        line-height: 13px;
        color: #8457ff;
        padding: 5.5px 8px;
        background: linear-gradient(0deg, #f2edff, #f2edff), #ffffff;
        border-radius: 24px;
      }
    }
  }
  .tabMenu {
    width: 196px;
    margin: 0 auto 32px;
    > li {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      margin-bottom: 10px;
      cursor: pointer;
      &.L1 {
        padding: 10px 20px;
        font-family: "Pretendard-Medium";
        font-size: 16px;
        line-height: 19px;
        color: #303441;
      }
      &.L2 {
        padding: 10px 40px;
        font-family: "Pretendard-Regular";
        font-size: 15px;
        line-height: 18px;
        color: #656975;
      }
      &.on,
      &:hover {
        background: #ffffff;
      }
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    min-height: fit-content;
    left: 0;
    background: none;
    .myInfo {
      flex-direction: row;
      justify-content: flex-start;
      width: auto;
      margin: 24px;
      .profile {
        margin: 0;
        width: 56px;
        height: 56px;
        font-size: 26px;

        .profileBtn {
          width: 24px;
          height: 24px;
          background-size: 24px auto;
        }
      }
      .flex-box {
        align-items: flex-start;
        padding: 0 20px;
        gap: 4px;
        .nickName {
          font-size: 18px;
          line-height: 22px;
        }
        .creater {
          font-size: 11px;
          line-height: 13px;
          padding: 5.5px 8px;
          background: linear-gradient(0deg, #f2edff, #f2edff), #ffffff;
          border-radius: 24px;
        }
      }
    }
    ul {
      &:last-of-type {
        margin-bottom: 32px;
      }
    }

// 320px 기준에서 SNB 글자가 깨지지 않도록 처리, 2025-01-09 이정우
// 플렉스 아이템 높이 42->75, 폰트 15 -> 12, 호버 시 간격 10 -> 5

    .tabMenu {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      /* top: 58px; */
      left: 0;
      width: 100%;
      height: 75px;
      background: #f7f9fa;
      padding: 3px 16px 0;
      margin-bottom: 0;

      li.L1 {
      flex: 1 1 0;
      width: 100%;
        margin: 0;
        font-size: 12px;
        text-align: center;
        line-height: 18px;
        color: #c0c5d1;
        padding: 8px 16px;

        &.on,
        &:hover {
          color: #303441;
          background: #f7f8fa;
          &::after {
            content: "";
            position: relative;
            top: 5px;
            display: block;
            width: calc(100%);
            height: 2px;
            background: #303441;
          }
        }
      }
    }
    .subTabMenu {
      display: flex;
      align-items: center;
      height: 42px;
      padding: 3px 16px 0;
      margin-bottom: 13px !important;
      li.L2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 28px;
        padding: 6px 12px;
        border-radius: 32px;
        color: #c0c5d1;
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
        &.on {
          color: #303441;
        }
        &:hover {
          background: #303441;
          color: #ffffff;
        }
      }
    }
  }
`;
const Main = styled.main`
  width: 892px;
  height: 100%;
  margin-left: 40px;
  h2 {
    margin: 60px 0 32px 0;
    font-family: "Pretendard-SemiBold";
    font-size: 32px;
    line-height: 38px;
    color: #303441;
  }
  .noContent {
    width: 100%;
    height: 466px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #c0c5d1;
    margin-bottom: 40px;
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    margin: 0;
    h2 {
      display: none;
    }
  }
`;
const ShowBtn = styled.div`
  text-align: right;
  > svg {
    margin-left: 8px;
    margin-bottom: 5px;
    cursor: pointer;
  }
`;
export default function MyLibrary() {
  const { id } = useParams();
  const { accessToken } = useUser();

  let _tabMode = '';
  let _tabModeTitle = '';
  
  if (id === "upload" ) {
    _tabMode = "내 콘텐츠";
    _tabModeTitle = "내 콘텐츠 관리";
  } else {
    _tabMode = "대시보드";
    _tabModeTitle = "대시보드";
  }

  const [tabModeTitle, setTabModeTitle] = useState(_tabModeTitle);
  const [tabMode, setTabMode] = useState(_tabMode);
  const [userData, setUserData] = useState([]);
  const [subTabMode, setSubTabMode] = useState(false);
  const [Photo, setPhoto] = useState(null);
  const [bgColor, setBgColor] = useState("");
  const [sortedData, setSortedData] = useState([]);
  
  const navigate = useNavigate();
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });
  



  const { data: myData, isLoading } = useQuery(
    ["myLibrary", accessToken],
    getMyLibraryList
  );
  const { data: myInfo, isLoading: isMyInforLoading } = useQuery(
    ["myInfo", accessToken],
    getMemberInfo
  );
  const { data: mySubscribeList, isLoading: isMySubscribeList } = useQuery(
    ["mySubscribeList", accessToken],
    getMySubscribeList
  );
  const { data: myEbookStat, isLoading: isMyEbookStatLoading } = useQuery(
    ["myEbookStat", accessToken],
    getMberEbookStat
  );
  
  const mSeq = myInfo?.row?.mSeq;
  const phothURL = myInfo?.row?.photoUrl;
  
  /**
   * 구독에서 데이터를 구독대상별로 가져오기 위해서 아래와 같이 조치한다.
   * 1. 선택한 구독대상을 저장할 수 있도록 한다.. selectedMSeq
   * 2. 구독대상을 선택하게 되면 tabMode 구독을 호출하도록 한다. => setTabMode("구독")
   ** START */
  const [selectedMSeq] = useRecoilState(selectedSubscribedUserState);

  useEffect(() => {
    changeData(selectedMSeq);
  }, [selectedMSeq]);
  /** END */

  const photo = () => {
    if (phothURL) {
      setPhoto(phothURL);
    }
    setBgColor(getThumbnailColor(mSeq));
  };

  const changeData = useCallback(
    (selectedMSeq) => {
      if (tabMode === "보관함") {
        const bookmarkList = myData?.row.bookmarkList || [];
        setSortedData([...bookmarkList]);
      }
      if (tabMode === "좋아요") {
        const favoliteList = myData?.row.favoliteList || [];
        setSortedData([...favoliteList]);
      }
      if (tabMode === "내 콘텐츠") {
        const uploadList = myData?.row.uploadList || [];
        setSortedData([...uploadList]);
        document.querySelector("svg.gridBtn").style.fill = '#F27D7D';
        document.querySelector("svg.listBtn").style.fill = '#D9D9D9';              

      }
      if (tabMode === "구독") {
        const contentList = mySubscribeList?.row.contentList || [];
        let mSeq = "";
        if (selectedMSeq !== "" && selectedMSeq !== undefined) {
          mSeq = selectedMSeq;
        } else {
          if (userData.length > 0) {
            mSeq = userData[0].mSeq;
          }
        }
        const filteredContentList = contentList.filter((contentList) => {
          if (contentList.mSeq === mSeq) {
            return contentList;
          }
        });

        setSortedData([...filteredContentList]);
        
      }
    },
    [tabMode, myData, mySubscribeList]
  );

  const getParam = (opt) => {
    const array = sortingByPram(sortedData, opt);
    setSortedData(array);
  };

  const updatePhoto = async (e) => {
    let formdata = new FormData();
    const newPhoto = e.target.files[0];
    if (newPhoto) {
      // console.log(e.target.files[0]);
      formdata.append("photoFile", newPhoto);

      const result = await updateMemberPhoto(formdata);
      if (result) {
        const resultMsg = result.resultMsg;
        alert(resultMsg);
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    if (!accessToken) {
      alert("잘못된 접근입니다. 로그인을 확인해주세요");
      navigate(`/`);
    }
    changeData();
  }, []);

  useEffect(() => {
    changeData();
  }, [changeData, myData]);

  useEffect(() => {
    if (mySubscribeList && mySubscribeList.row) {
      changeData();
      setUserData([...mySubscribeList.row.userList]);
    }
  }, [changeData, mySubscribeList]);

  useEffect(() => {
    photo();
  }, [myInfo]);

  useEffect(() => {
    changeData();
  }, [tabMode, changeData]);

  const SideTabWrap = () => {
    return (
      <SideTab bg={Photo} bgColor={bgColor}>
        <div className="myInfo">
          <div className="profile">
            {Photo ? "" : myInfo?.row?.nickName.charAt(0)}
            <input
              id="file"
              type="file"
              accept="image/jpeg, image/jpg, image/png"
              onInput={updatePhoto}
            />
            <label
              className="profileBtn"
              htmlFor="file"
              id="file"
              name="사진변경"
            ></label>
          </div>
          <div className="flex-box">
            <span className="nickName">{myInfo?.row?.nickName}</span>
            <span className="creater">크리에이터</span>
          </div>
        </div>

        <ul className="tabMenu">
          <li
              className={`L1 ${tabMode === "대시보드" ? "on" : ""}`}
              onClick={() => {
                setTabMode("대시보드");
                setTabModeTitle("대시보드");
              }}
            >
            대시보드
          </li>              
          <li
              className={`L1 ${(tabMode === "내 콘텐츠" || tabMode==="콘텐츠 관리") ? "on" : ""}`}
              onClick={() => {
                setTabMode("내 콘텐츠");
                setTabModeTitle("내 콘텐츠 관리");
                // setSubTabMode(true);
              }}
            >
            내 콘텐츠 관리
          </li>          
          <li
            className={`L1 ${tabMode === "보관함" ? "on" : ""}`}
            onClick={() => {
              setTabMode("보관함");
              setTabModeTitle("보관함");
            }}
          >
            보관함
          </li>
          <li
            className={`L1 ${tabMode === "좋아요" ? "on" : ""}`}
            onClick={() => {
              setTabMode("좋아요");
              setTabModeTitle("좋아요");
            }}
          >
            좋아요
          </li>          
          <li
            className={`L1 ${tabMode === "구독" ? "on" : ""}`}
            onClick={() => {
              setTabMode("구독");
              setTabModeTitle("구독");
            }}
          >
            구독
          </li>

      
        </ul>
      </SideTab>
    );
  };  

  return (
    <Layout>
      <>
        {(isLoading || isMyInforLoading || isMySubscribeList) && <Loading />}
        {tablet && <SideTabWrap />}
        <Container flex={!tablet ? true : false}>
          {!tablet && <SideTabWrap />}
          <Main>
            <h2>{tabModeTitle}</h2>
            {(tabMode === "내 콘텐츠" || tabMode === "콘텐츠 관리") && (
              <ShowBtn>
                <GridBtn
                  className="gridBtn"
                  onClick={() => {
                    setTabMode("내 콘텐츠");
                    document.querySelector("svg.gridBtn").style.fill = '#F27D7D';
                    document.querySelector("svg.listBtn").style.fill = '#D9D9D9';
                  }}
                  fill="#F27D7D"
                />
                <ListBtn
                  className="listBtn"
                  onClick={() => {
                    setTabMode("콘텐츠 관리");
                    document.querySelector("svg.gridBtn").style.fill = '#D9D9D9';
                    document.querySelector("svg.listBtn").style.fill = '#F27D7D';
                  }}
                  fill="#D9D9D9"
                />
              </ShowBtn>
            )}

            {tabMode === "보관함" && (
              <>
                <Subfilter filter={true} getParam={getParam} />
                {sortedData.length > 0 ? (
                  <ThumbGrid data={sortedData} myRoom={true} />
                ) : (
                  <div className="noContent">
                    마이룸에 추가한 콘텐츠가 여기에 표시됩니다.
                  </div>
                )}
              </>
            )}
            {tabMode === "좋아요" && (
              <>
                <Subfilter filter={true} getParam={getParam} />
                {sortedData.length > 0 ? (
                  <ThumbGrid data={sortedData} myRoom={true} />
                ) : (
                  <div className="noContent">
                    좋아요를 선택한 콘텐츠가 여기에 표시됩니다.
                  </div>
                )}
              </>
            )}            
            {tabMode === "구독" && (
              <>
                <UserList users={userData} />
                <Subfilter filter={true} getParam={getParam} />
                {sortedData.length > 0 ? (
                  <ThumbGrid data={sortedData} myRoom={true} />
                ) : (
                  <div className="noContent">
                    구독한 사용자의 콘텐츠가 여기에 표시됩니다.
                  </div>
                )}
              </>
            )}
            {tabMode === "내 콘텐츠" && (
              <>
                <Subfilter filter={true} getParam={getParam} />
                {sortedData.length > 0 ? (
                  <ThumbGrid data={sortedData} myRoom={true} />
                ) : (
                  <div className="noContent">
                    업로드한 콘텐츠가 여기에 표시됩니다.
                    <br />
                    콘텐츠를 만들어 보세요.
                  </div>
                )}
              </>
            )}
            {tabMode === "콘텐츠 관리" && <ContentsManager/>}
            {tabMode === "대시보드" && <Statistics/>}
          </Main>
        </Container>
      </>
    </Layout>
  );
}
