import { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useInfiniteScroll } from "../hook/useInfiniteScroll";
import { useTemplateData } from "../hook/useTemplateData";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import Container from "../components/Container";
import NewContainer from "../components/NewContainer";
import ThumbGridTemplate from "../components/ThumbGridTemplate";
import EbookContainer from "../components/EbookContainer";
import Spacer from "../components/Spacer";
import Loading from "../components/Loading";
import banner from "../images/pages/ContentGuide/contentGuideBanner.png";
import styled from "styled-components";
import IconContainer from "../components/IconContainer";
import HashtagTemplate from "../components/HashtagTemplate";

const Greeting = styled.section`
  width: 100%;
  height: 200px;
  aspect-ratio: 1200/624;
  margin: 60px auto 0px;
  box-sizing: border-box;

  display: column;
  text-align: center; 

  div.intro{
    margin-bottom: 20px;
    font-family: "Pretendard-Light";
    font-size: 48px;
    line-height: 60px;
    color: #303441;
    text-align: center;
    @media ${({ theme }) => theme.device.tablet} {
      font-size: 4vh;
      line-height: 5vh;
      margin-bottom: 20px;
    }    
  }
  
  div.btnbox{
    width: 150px;
    height: 40px;
    
    display: inline-block;
    line-height: 40px;
    text-align: center;
  }

  #btnbox1{
    color: white;
    border: 1px solid red;
    background-color: red;
    border-radius: 10px;
  }
  
  #btnbox2{
    color: red;
    background-color: white;
    border: 1px solid red;
    border-radius: 10px;
  }

  #btnbox3{
    width: 15px;
    height: 40px;
  }
  
  @media ${({ theme }) => theme.device.tablet} {
    min-width: 100%;
    width: 100%;
    margin: 10px auto 0px;
    height: 150px;
  }  
`;

const Banner = styled.div`
  width: 1168px;
  height: 318px;

  background: url(${banner}) center / contain no-repeat;
  margin: 10px auto 10px;
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    height: auto;
    aspect-ratio: 375/100;
    margin: 0 auto 40px;
  }
`;

const IconList = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
  margin: 40px auto;

  @media ${({ theme }) => theme.device.tablet} {
  min-width: 100%;
  width: 100%;
  height: auto;
  margin: 40px 0 0 0;
  }
`;

export default function Template() {
  const location = useLocation();
  const ordering = location.state?.options?.ordering ?? 0;
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, contentData, hasMore } = useTemplateData(ordering, currentPage);
  const { lastElementRef } = useInfiniteScroll(isLoading, hasMore, setCurrentPage);

  const user = navigator.userAgent;
  const windowInnerWidth = window.innerWidth;

if ((((user.indexOf("iPhone") > -1 || user.indexOf("Android")) > -1)) && (windowInnerWidth < 480)) {
    return (
      <Layout>
        {isLoading ? <Loading /> : null}
        <HashtagTemplate />
{/*
        <Banner/>
*/}
        <Greeting>
          <div className="intro">
            나 만의 책을 <br/> 만들어 보세요
          </div>
          <div id = "btnbox1" className = "btnbox"> 
            <Link to="/contentGuide" className="btnbox"> 바로 시작하기 </Link>
          </div>
        </Greeting>
        <IconList>
          <IconContainer />
        </IconList>
        <PageTitle
          title="최신 템플릿"
          kind="moreEbooks"
          description="고퀄리티 콘텐츠를 쉽고 빠르게 완성"
        />
{/*
        <Spacer height={"0px"} /> 
*/}
        <Container>
          <EbookContainer>
            <ThumbGridTemplate data={contentData} lastElementRef={lastElementRef} />
          </EbookContainer>
        </Container>
      </Layout>
    );
  }
  else{
    return (
      <Layout>
        {isLoading ? <Loading /> : null}
        <HashtagTemplate />
{/*
        <Banner/>
*/}
        <Greeting>
          <div className="intro">
            나 만의 책을 <br/> 만들어 보세요
          </div>
          <div id = "btnbox1" className = "btnbox"> 
            <Link to="/contentGuide" className="btnbox"> 바로 시작하기 </Link>
          </div>
        </Greeting>
        <IconList>
          <IconContainer />
        </IconList>
        <PageTitle
          title="최신 템플릿"
          kind="moreEbooks"
          description="고퀄리티 콘텐츠를 쉽고 빠르게 완성"
          location="template"
        />
{/*
        <Spacer height={"0px"} />
*/}
        <NewContainer>
          <EbookContainer>
            <ThumbGridTemplate data={contentData} lastElementRef={lastElementRef} />
          </EbookContainer>
        </NewContainer>
      </Layout>
    );    
  }
}
