import styled from "styled-components";

const Wraper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 250px;
  height: 56px;
  font-family: "Pretendard-SemiBold";
  font-size: 22px;
  line-height: 26px;

  color: #ffffff;
  background: #1967d2;
  border: 3px solid #203864;
  border-radius: 6px;
  padding: 0;
  cursor: pointer;
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #275bf6;
  }
  .icon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(${(props) => props.imgSrc}) center/100% no-repeat;
  }
  @media ${({ theme }) => theme.device.tablet} {
    width: 164px;
    height: 54px;
    font-size: 15px;
    line-height: 18px;
    .icon {
      width: 16px;
      height: 16px;
    }
  }
`;

export default function ButtonBlue({ imgSrc, text, onClickCallback }) {
  return (
    <Wraper
      imgSrc={imgSrc}
      onClick={() => {
        onClickCallback();
      }}
    >
      {imgSrc && <span className="icon"></span>} {text}
    </Wraper>
  );
}
