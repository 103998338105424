import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
const Wrap = styled.div`
  /* height: 88px; */
  padding: 0 0 10px;
  font-size: 14px;
  line-height: 17px;
  color: #303441;
  display: flex;
  align-items: center;
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 0 auto;
    > li {
      position: relative;
      padding-right: 16px;
      font-size: 13px;
      line-height: 16px;
      color: #adb3bd;
      cursor: pointer;
      &:not(:first-child) {
        padding-left: 16px;
        /* border-left: 1px solid ${(props) => props.theme.gray400}; */
        &::after {
          content: "";
          position: absolute;
          display: block;
          top: 2px;
          left: 0;
          width: 1px;
          height: 12px;
          background-color: #adb3bd;
        }
      }
      &.on {
        color: #303441;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    padding: 0 0 20px;
    ul > li {
      padding-right: 10px;
      &:not(:first-child) {
        padding-left: 10px;
      }
    }
  }
`;

export default function Subfilter({ filter, getParam }) {
  const [ordering, setOrdering] = useState("regDate");
  useEffect(() => {
    getParam(ordering);
  }, [ordering]);
  return (
    <>
      {filter && (
        <Wrap>
          <ul>
            <li
              className={ordering === "regDate" ? "on" : ""}
              onClick={() => setOrdering("regDate")}
              value="regDate"
            >
              최신순
            </li>
            <li
              className={ordering === "viewCnt" ? "on" : ""}
              onClick={() => setOrdering("viewCnt")}
              value="viewCnt"
            >
              조회수순
            </li>
            <li
              className={ordering === "favorCnt" ? "on" : ""}
              onClick={() => setOrdering("favorCnt")}
              value="favorCnt"
            >
              하트순
            </li>
          </ul>
        </Wrap>
      )}
    </>
  );
}
