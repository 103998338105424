import styled from "styled-components";
import HorizontalInput from "../components/HorizontalInput";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import { set, useForm } from "react-hook-form";
import { useState } from "react";
import Modal from "../components/Modal";
import { useRecoilState, useRecoilValue } from "recoil";
import { modalState } from "../atoms";
import Container from "../components/Container";
import { useNavigate } from "react-router-dom";
import { appendFeedback } from "../core/api";
import { useMediaQuery } from "react-responsive";

const Main = styled.main`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;
const Ex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  /* margin-top: 40px; */
  strong {
    font-size: 18px;
    color: ${(props) => props.theme.gray1000};
    @media ${({ theme }) => theme.device.tablet} {
      font-size: 13px;
    }
  }
  span {
    font-size: 18px;
    color: ${(props) => props.theme.gray400};
    @media ${({ theme }) => theme.device.tablet} {
      font-size: 13px;
    }
    em {
      color: ${(props) => props.theme.point01};
      margin-right: 2px;
    }
  }
  @media ${({ theme }) => theme.device.tablet} {
    margin-bottom: 4px;
  }
`;
const Btns = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 40px;
  button,
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    height: 36px;
    border-radius: 6px;
    border: none;
    margin: 0px 8px;
    font-size: 16px;
    cursor: pointer;
  }
  span {
    background-color: ${(props) => props.theme.gray300};
    color: ${(props) => props.theme.gray600};
  }
  button {
    background-color: ${(props) => props.theme.gray600};
    color: #fff;
  }
`;

export default function Feedback() {
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  const [isOpen, setIsOpen] = useRecoilState(modalState);
  const [fileName, setFileName] = useState("screenshot.png");
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });
  const onValid = async (form) => {
    let formdata = new FormData();
    formdata.append("title", form.title);
    formdata.append("content", form.content);
    if (form.file) formdata.append("imageFile", form.file[0]);
    if (form.homepage) formdata.append("homepage", form.homepage);
    const result = await appendFeedback(formdata);
    const returnInt = result.returnInt;
    // console.log(form);
    // console.log(result);
    if (returnInt === 0) alert(result.resultMsg);
    if (returnInt === 1) setIsOpen(true);
  };

  const onInputChange = (e) => {
    let file = e.target.files[0];
    // console.log("onInputChange", file);
    setFileName(file.name);
  };

  return (
    <Layout>
      <PageTitle title="피드백" kind="feedback" />
      <Container>
        <Main>
          <Ex>
            <strong>문제를 자세히 설명해 주세요.</strong>
            <span>
              <em>*</em>필수 입력 항목
            </span>
          </Ex>
          <form onSubmit={handleSubmit(onValid)}>
            <HorizontalInput
              kind="base"
              title="제목"
              type="text"
              placeholder="제목을 입력하세요."
              required
              register={register("title", { required: true })}
              borderTop={true}
            />
            <HorizontalInput
              kind="textarea"
              title="내용"
              type="text"
              placeholder="내용을 자세히 입력하세요."
              required
              register={register("content", { required: true })}
            />
            <HorizontalInput
              kind="base"
              title="홈페이지"
              type="text"
              placeholder="홈페이지 URL을 입력하세요."
              register={register("homepage")}
            />
            <HorizontalInput
              kind="file"
              title="첨부파일"
              type="file"
              placeholder="screenshot.png"
              register={register("file")}
              borderBottom={true}
              onInput={onInputChange}
              fileName={fileName}
            />
            <Btns>
              <span onClick={() => navigate("/")}>취소</span>
              <button>보내기</button>
            </Btns>
          </form>
        </Main>
      </Container>
      {isOpen ? (
        <Modal width={tablet ? "343" : "470"} isDark={tablet ? true : false}>
          <p>
            소중한 정보 감사합니다.
            <br />
            보내주신 의견은 검토 후 회신드리도록 {tablet && <br />}하겠습니다.
          </p>
        </Modal>
      ) : null}
    </Layout>
  );
}
