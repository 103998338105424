import styled from "styled-components";
import imgPrevBtn from "../images/components/bannerSlider/prevBtn.svg";
import imgNextBtn from "../images/components/bannerSlider/nextBtn.svg";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import banner_1 from "../images/components/bannerSlider/banner_1.png";
import banner_2 from "../images/components/bannerSlider/banner_2.png";
import banner_3 from "../images/components/bannerSlider/banner_3.png";
import banner_4 from "../images/components/bannerSlider/banner_4.png";
import banner_5 from "../images/components/bannerSlider/banner_5.png";
import banner_m_1 from "../images/components/bannerSlider/banner_m_1.png";
import banner_m_2 from "../images/components/bannerSlider/banner_m_2.png";
import banner_m_3 from "../images/components/bannerSlider/banner_m_3.png";
import banner_m_4 from "../images/components/bannerSlider/banner_m_4.png";
import banner_m_5 from "../images/components/bannerSlider/banner_m_5.png";
import { Link } from "react-router-dom";

const Slider = styled.div`
  position: relative;
  min-width: 1200px;
  width: 1200px;
  height: 100%;
  aspect-ratio: 1200/365;
  padding: 0 16px;
  margin: 0 auto;
  ul.images {
    position: relative;
    width: 100%;
    height: 100%;
    li {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      /* pointer-events: none; */
      overflow: hidden;
      display: none;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
      &.on {
        display: block;
      }
      a {
        position: absolute;
        display: block;
        width: 134px;
        height: 48px;
        /* background-color: aqua;
        opacity: 0.3; */
        &.guide {
          top: 389px;
          left: 90px;
        }
        &.contentRequest {
          top: 407px;
          left: 90px;
        }
        &.solutionRequest {
          top: 407px;
          left: 90px;
        }
        &.event {
          top: 113px;
          left: 745px;
        }
      }
    }
  }
  .slideCount {
    position: absolute;
    bottom: 23px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(71, 71, 71, 0.2);
    padding: 10px 20px;
    border-radius: 20px;
    font-family: "NEXON Lv1 Gothic";
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }
  @media ${({ theme }) => theme.device.tablet} {
    min-width: 100%;
    width: 100%;
    height: auto;
    padding: 0;
    aspect-ratio: 375/200;
    ul.images {
      li {
        border-radius: 0px;

        a {
          width: 27.7%;
          height: 13%;
          &.guide,
          &.contentRequest,
          &.solutionRequest {
            top: 73.4%;
            left: 8.6%;
          }
          &.event {
            top: 5.9%;
            left: 68.4%;
          }
        }
      }
    }
    .slideCount {
      left: auto;
      right: 10px;
      bottom: 10px;
      transform: translateX(0);
      padding: 8px 14px;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;
const SliderBtns = styled.div`
  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    width: 23px;
    height: 57px;
    margin-top: -16px;
    border-radius: 50%;
    /* background-color: #ffffff; */
    cursor: pointer;
    &.prevBtn {
      left: 26px;
      background: url(${imgPrevBtn}) center / 100% auto no-repeat;
    }
    &.nextBtn {
      right: 26px;
      background: url(${imgNextBtn}) center / 100% auto no-repeat;
    }
  }
`;

export default function BannerContainer() {
  const [slideCount, setSlideCount] = useState(1);
  const imagesRef = useRef("");
  const [imagesLength, setImagesLength] = useState(null);
  let images;
  let index;
  const [loop, setLoop] = useState();
  let isPause = useRef(false);
  let startPos = 0;
  let offset = 0;
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });
  // const requestRef = useRef(0);
  // const [time, setTime] = useState(0);
  // const animate = (timer) => {
  //   // 여기에 원하는 애니메이션 수행 코드 작성
  //   console.log("timer", timer);
  //   var currTime = new Date().getTime();
  //   console.log("currTime", currTime);
  //   if (timer > 10000) {
  //     // nextEvent();
  //     requestRef.current = requestAnimationFrame(animate);
  //   }

  // };

  // return () => cancelAnimationFrame(requestRef.current);
  useEffect(() => {
    slideInit();
    autoSlide();
    // requestRef.current = requestAnimationFrame(animate);
    return () => {
      pauseSlide();
    };
  }, []);

  const slideInit = () => {
    if (imagesRef) {
      images = imagesRef.current.childNodes;
      images[0].classList.add("on");
      setImagesLength(images.length);
    }
  };

  const imgOFF = () => {
    if (imagesRef) {
      if (imagesRef.current.childNodes) {
        images = imagesRef.current.childNodes;
        images.forEach(function (img, idx) {
          if (img.classList.contains("on")) index = idx;
          img.classList.remove("on");
        });
      }
    }
  };

  const prevEvent = () => {
    isPause.current = true;
    clearInterval(loop);
    if (imagesRef) {
      imgOFF();
      if (index - 1 === -1) index = images.length;
      images[index - 1].classList.add("on");
      setSlideCount(index);
    }
    playSlide();
  };

  const nextEvent = () => {
    isPause.current = true;
    clearInterval(loop);
    if (imagesRef) {
      imgOFF();
      if (index + 1 === images.length) index = -1;
      images[index + 1].classList.add("on");
      setSlideCount(index + 2);
    }
    playSlide();
  };

  const autoSlide = () => {
    if (imagesRef) {
      const intervlal = setInterval(() => {
        if (!isPause.current) {
          imgOFF();
          if (index + 1 === images.length) index = -1;
          images[index + 1].classList.add("on");
          setSlideCount(index + 2);
        } else {
          clearInterval(intervlal);
        }
      }, 4000);
      setLoop(intervlal);
    }
  };

  const pauseSlide = () => {
    isPause.current = true;
    clearInterval(loop);
    // console.log("정지");
  };

  const playSlide = () => {
    if (isPause.current) {
      isPause.current = false;
      autoSlide();
      // console.log("재생");
    }
  };
  const onClick = (e) => {
    // console.log("click", e.target);
  };

  return (
    <Slider>
      <ul
        ref={imagesRef}
        className="images"
        // onMouseOver={() => {
        //   pauseSlide();
        //   console.log("in");
        // }}
        // onMouseLeave={() => {
        //   playSlide();
        //   console.log("out");
        // }}
        onTouchStart={(e) => {
          startPos = e.touches[0].pageX;
          // console.log("onTouchStart", startPos);
        }}
        onTouchMove={(e) => {
          offset = e.targetTouches[0].pageX - startPos;
        }}
        onTouchEnd={() => {
          if (offset < 0) nextEvent();
          if (offset > 0) prevEvent();
        }}
      >
        {/* <li>
          <img src={!tablet ? banner_1 : banner_m_1} alt="Imstory" />
        </li> */}
        <li>
        
          <img src={!tablet ? banner_2 : banner_m_2} alt="크리에이터되기 바로가기" />
          <Link to="/guide" className="guide"></Link>
           {/* <a href="/guide" className="guide">TEST</a> */}
        </li>
        <li>
          <img src={!tablet ? banner_3 : banner_m_3} alt="콘텐츠제작서비스 문의하기" />
          <Link className="contentRequest" to="/request"></Link>
          {/* <a className="contentRequest" href="mailto:help@tovsoft.com"></a> */}
        </li>
        <li>
          <img src={!tablet ? banner_4 : banner_m_4} alt="솔루션 문의하기" />
          <Link className="solutionRequest" to="/solution"></Link>
          {/* <a className="solutionRequest" href="mailto:help@tovsoft.com"></a> */}
        </li>
        {/* <li>
          <img src={!tablet ? banner_5 : banner_m_5} alt="Imstory오픈이벤트" />
          <Link className="event" to="/event"></Link>
        </li> */}
      </ul>
      {!tablet && (
        <SliderBtns>
          <div className="prevBtn" onClick={prevEvent}></div>
          <div className="nextBtn" onClick={nextEvent}></div>
        </SliderBtns>
      )}
      <div className="slideCount">
        {slideCount} / {imagesLength}
      </div>
    </Slider>
  );
}
